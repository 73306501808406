import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { t } from '@lingui/macro';

import { List, ListItem, Tooltip } from '@mui/material';

import LanguageButton from './settings/LanguageButton';
import config from '~common/config';
import { useWindowSize } from '~common/utils/layout.utils';
import { CloseIcon } from '~misc/icons';
import Typography from '~misc/Typography';
import Drawer from '~sections/Drawer';
import NaviLinkBase from '~navigation/NaviLink';
import { app } from '~common/app.model';
import { useActions } from '~common/utils/hooks.utils';
import { getLangValue } from '~common/app.utils';
import {
  AccordionListItem,
  AdminListItem,
  LinkText,
  NavigationListItem,
} from '~common/navigation/DrawerListItem';
import { isMobile } from '~common/utils/styled.utils';

interface Props {
  Content?: React.FC;
}

function LeftDrawer({ Content = DrawerContent }: Props) {
  const { open } = useSelector(state => state.app.leftDrawer);
  const user = useSelector(state => state.app.user);
  const userRights = useSelector(state => state.app.userRights);
  const customer = useSelector(state => state.app.customer);
  const customerConfig = useSelector(state => state.app.customer?.configById);
  const magentoLoginUrl = customerConfig?.['magento.login.url'];

  const { toggleDrawer, logout } = useActions(app.actions);

  const { innerWidth } = useWindowSize();
  const mobile = isMobile(innerWidth);

  const closeDrawer = () => {
    toggleDrawer(false);
  };

  return (
    <Drawer
      open={!!user && open}
      onOpen={() => toggleDrawer(true)}
      onClose={closeDrawer}
      contentStyle={{ paddingTop: 8 }}
    >
      <ExitButtonContainer>
        <IconButton aria-label={t`Close`} onClick={closeDrawer}>
          <CloseIcon />
        </IconButton>
      </ExitButtonContainer>

      <StyledList>
        <Content />
        {innerWidth <= 1300 && (
          <>
            {userRights?.MAGENTO_ORDER &&
              mobile &&
              !!magentoLoginUrl &&
              customer?.configById['show.magento.directLink'] !== false && (
                <MagentoLoginLink
                  to="#"
                  data-test="login-to-magento"
                  onClick={async () => {
                    window.open(magentoLoginUrl as string, '_blank');
                  }}
                >
                  <LinkText text={t`PunaMusta 24/7`} />
                </MagentoLoginLink>
              )}
            <StyledUserInfo>
              <Typography variant="h1" color="primary">
                {user && user.firstName}
                &nbsp;
                {user && user.familyName}
              </Typography>
            </StyledUserInfo>
            {userRights?.OWN_SETTINGS_MODIFY &&
              customer?.configById['show.language.select'] !== false &&
              (customer?.languages ?? []).length > 1 && (
                <ListItem>
                  <LanguageButton drawer />
                </ListItem>
              )}
            {(userRights?.OWN_SETTINGS_MODIFY ||
              userRights?.OWN_SETTINGS_CHANGE_PASSWORD) && (
              <StyledUserNaviLink
                to="/settings"
                data-test="navigate-to-user"
                onClick={closeDrawer}
              >
                <LinkText text={t`Settings`} />
              </StyledUserNaviLink>
            )}
            {customer?.configById['custom.appbar.enabled'] === true ? null : (
              <StyledUserNaviLink
                to="#"
                data-test="log-out"
                onClick={e => {
                  e.preventDefault();
                  closeDrawer();
                  logout();
                }}
              >
                <LinkText text={t`Log out`} />
              </StyledUserNaviLink>
            )}
            {customer?.configById['custom.appbar.enabled'] === true ||
            user?.lastAuthType !== 10 ||
            customer?.configById['azure.logout.choice'] !== true ? null : (
              <Tooltip
                title={t`Log out with keep azure session`}
                placement="right"
              >
                <StyledUserNaviLink
                  to="#"
                  data-test="log-out-restricted"
                  onClick={e => {
                    e.preventDefault();
                    closeDrawer();
                    logout(true);
                  }}
                >
                  <LinkText text={t`Local log out`} />
                </StyledUserNaviLink>
              </Tooltip>
            )}
          </>
        )}
      </StyledList>
    </Drawer>
  );
}

function DrawerContent() {
  const customerFunctions = useSelector(state => state.app.customerFunctions);
  const settings = useSelector(state => state.app.settings);
  const userRights = useSelector(state => state.app.userRights);
  const customer = useSelector(state => state.app.customer);

  const contentLink =
    customer?.configById['content.browse.settings.mode.default'] === 'search'
      ? `/search`
      : `/folders/${settings?.contentFolderId}`;

  const workspacesLink = `/workspaces/${settings?.workspacesFolderId}`;
  const archiveLink = `/archive/${settings?.archiveFolderId}`;

  return (
    <>
      {userRights?.SHOW_FRONTPAGE && (
        <NavigationListItem
          href="/home"
          text={t`Home`}
          data-test="navigate-to-home"
        />
      )}
      <NavigationListItem
        href={contentLink}
        text={t`Content(aineistot)`}
        data-test="navigate-to-content"
      />
      {userRights?.SHOW_OWN_MATERIAL && (
        <NavigationListItem
          href={`/folders/${settings?.userContentFolderId}`}
          text={t`My content`}
          data-test="navigate-to-personal-content"
        />
      )}
      {userRights?.CARTS_MANAGE && (
        <NavigationListItem
          href={workspacesLink}
          text={t`Workspaces`}
          data-test="navigate-to-workspaces"
        />
      )}
      {userRights?.ARCHIVE_MANAGE && (
        <NavigationListItem
          href={archiveLink}
          text={t`Archive(noun)`}
          data-test="navigate-to-archive"
        />
      )}
      {userRights?.ORDERS_MANAGE &&
        (userRights?.ORDERS_SHOW_ALL || userRights?.ORDERS_SHOW_OWN) && (
          <NavigationListItem
            href="/orders"
            text={t`Orders`}
            data-test="navigate-to-orders"
          />
        )}
      {customerFunctions?.FN_MB_COMMENT_REQUESTS &&
        userRights?.COMMENT_REQUESTS_MANAGE &&
        (userRights?.COMMENT_REQUESTS_SHOW_ALL ||
          userRights?.COMMENT_REQUESTS_SHOW_OWN) && (
          <NavigationListItem
            href="/comments"
            text={t`Comment requests`}
            data-test="navigate-to-comments"
          />
        )}
      {customerFunctions?.F_REPORTS && userRights?.REPORTS_VIEW && (
        <NavigationListItem
          href="/reports"
          text={t`Reporting`}
          data-test="navigate-to-reports"
        />
      )}
      {userRights?.MATERIAL_MANAGE_SYNKKA && (
        <NavigationListItem
          href="/files/synkka"
          text={t`Synkka management`}
          data-test="navigate-to-synkka-management"
        />
      )}
      <AccordionListItem
        childItems={[
          {
            href: '/users',
            text: t`Users`,
            'data-test': 'navigate-to-users',
            hidden: !userRights?.USERS_MANAGE,
          },
          {
            href: '/usergroups',
            text: t`User groups`,
            'data-test': 'navigate-to-usergroups',
            hidden: !userRights?.USER_GROUPS_MANAGE,
          },
          {
            href: '/uploadforms',
            text: t`Upload forms`,
            'data-test': 'navigate-to-uploadforms',
            hidden: !userRights?.CONTENT_FORMS_MANAGE,
          },
          {
            href: '/categorytree',
            text: t`Category tree`,
            'data-test': 'navigate-to-edit-metatree',
            hidden: !userRights?.META_TREE_MODIFY,
          },
          {
            href: `${config.url}/NiboWEB/${config.customer}/frontpage.do`,
            text: t`Admin UI`,
            'data-test': 'navigate-to-administration',
            external: true,
            hidden: !settings?.isAdmin,
          },
        ]}
        text={t`Administration`}
      />
      {userRights?.SHOW_HELP && customer?.configById['help.links'] && (
        <AdminListItem
          href={getLangValue(customer.configById['help.links'])}
          data-test="navigate-to-help"
          text={t`User manual`}
        />
      )}
      {userRights?.SHOW_ADMIN_HELP &&
        customer?.configById['help.admin.links'] && (
          <AdminListItem
            href={getLangValue(customer.configById['help.admin.links'])}
            data-test="navigate-to-admin-help"
            text={t`Administration manual`}
          />
        )}
    </>
  );
}

const StyledList = styled(List)`
  color: ${props => props.theme.palette.primary.main};
`;

// TODO: remove button reference, and style with css
const StyledUserInfo = styled.li`
  && {
    margin-top: 50px;
    padding: ${props =>
      `${props.theme.spacing(1)} ${props.theme.spacing(1.5)}`};
    text-transform: none;
  }

  @media screen and (min-width: 1300px) {
    display: none !important;
  }
`;

const ExitButtonContainer = styled.div`
  display: flex;
  width: 100%;
  padding: 0 ${props => props.theme.spacing(1)};
  justify-content: flex-end;
`;

const StyledUserNaviLink = styled(NaviLinkBase)`
  color: ${({ theme }) => theme.palette.common.white};
`;

const IconButton = styled.button`
  padding: ${p => p.theme.spacing(1)};
  margin: 0;
  background-color: unset;
  border: none;
  display: flex;
  cursor: pointer;
`;

const MagentoLoginLink = styled(NaviLinkBase)`
  color: ${({ theme }) => theme.palette.common.white};
`;

export default LeftDrawer;
