import format from 'date-fns/format';
import parse from 'date-fns/parse';

/**
 * ISO format datetime string to human readable finnish localization
 * with only the date
 */
export function getDateString(date: string | Date) {
  return (typeof date === 'string' ? new Date(date) : date).toLocaleString(
    'fi',
    {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    }
  );
}

/**
 * ISO format datetime string to human readable finnish localization
 * with date and time
 */
export function getDateTimeString(date: string): string {
  return new Date(date).toLocaleString('fi', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  });
}

/**
 * Returns the difference of two Dates in an integer of days.
 * if `a` is a date before `b`, returns a negative number.
 */
export function dateDiffInDays(a: Date, b: Date) {
  const _MS_PER_DAY = 1000 * 60 * 60 * 24;

  // Discard the time and time-zone information.
  const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
  const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());

  return Math.floor((utc2 - utc1) / _MS_PER_DAY);
}

/** Returns a `yyyy-MM-dd` formatted date string from data given by FormDatePicker */
export function getDate(date: string) {
  return format(new Date(date), 'yyyy-MM-dd');
}

/** Returns a `HH:mm` formatted date string from data given by FormTimePicker */
export function getTime(time: string) {
  return format(new Date(time), 'HH:mm');
}

/** Returns an API-compatible datetime string from given `yyyy-MM-dd` and `HH:mm` strings */
export function getApiDateTimeString(date: string, time: string) {
  const sharingEndDate = new Date(`${date}T${time}`);
  return sharingEndDate.toISOString();
}
/** Returns formatting string from given date, if none match return null */
export function inferDateFormat(date: string): string | null {
  const formats = {
    'dd.MM.yyyy': /^\d{2}\.\d{2}\.\d{4}$/,
    'yyyy-MM-dd': /^\d{4}-\d{2}-\d{2}$/,
    'dd-MM-yyyy': /^\d{2}-\d{2}-\d{4}$/,
    yyyyMMdd: /^\d{8}$/,
  };

  for (const [format, pattern] of Object.entries(formats)) {
    if (date.trim().match(pattern)) return format;
  }
  return null;
}

/** Parse string with undefined format (uses above formats from inferDateFormat)
 *  You could use Date.parse(), but the behaviour is browser specific */
export function parseUnknownFormatDateString(date: string): Date | null {
  const inputFormat = inferDateFormat(date);
  if (inputFormat === null) return null;
  return parse(date, inputFormat, new Date());
}

/** Simple helper to detect date objects which are invalid
 * (meaning .toString() returns 'Invalid Date') */
export function isValidDateObject(date: any): boolean {
  return date instanceof Date && !isNaN(date.getTime());
}

/** Format 'unknown' date string to desired format */
export function reformatDateString(
  date: string,
  outputFormat = 'dd.MM.yyyy'
): string | null {
  try {
    const dateObject = parseUnknownFormatDateString(date);
    return dateObject !== null ? format(dateObject, outputFormat) : null;
  } catch {
    return date;
  }
}
