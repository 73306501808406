import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import Toolbar from '@mui/material/Toolbar';
import { t, Trans } from '@lingui/macro';
import { Help } from '@mui/icons-material';
import LanguageButton from './settings/LanguageButton';
import ShoppingCartButton from './content/workspaces/shopping';
import { useWindowSize } from '~common/utils/layout.utils';

import AppBar from '~sections/AppBar';
import LogoLink from '~navigation/LogoLink';
import IconButton from '~inputs/IconButton';
import ModalButton from '~navigation/ModalButton';
import MenuButton from '~navigation/MenuButton';
import {
  LogoutIcon,
  MenuIcon,
  SearchIcon,
  SettingsIcon,
  UserIcon,
} from '~misc/icons';
import { getLangValue } from '~common/app.utils';
import { useActions } from '~common/utils/hooks.utils';
import { app } from '~common/app.model';
import { useRedirectTo } from '~common/navigation/useRedirectTo';
import { isMobile } from '~common/utils/styled.utils';

interface Props {
  isJCDecauxUI?: boolean;
}

function Bar({ isJCDecauxUI }: Props) {
  const userRights = useSelector(state => state.app.userRights);
  const settings = useSelector(state => state.app.settings);
  const customer = useSelector(state => state.app.customer);
  const share = useSelector(state => state.app.share);
  const drawerEnabled = useSelector(state => state.app.leftDrawer.enabled);
  const user = useSelector(state => state.app.user);
  const customerConfig = useSelector(state => state.app.customer?.configById);
  const magentoLoginUrl = customerConfig?.['magento.login.url'];

  const { toggleDrawer, logout } = useActions(app.actions);
  const redirectTo = useRedirectTo();

  const userOptions = [
    {
      value: 'settings',
      title: t`Settings`,
      hidden:
        !userRights?.OWN_SETTINGS_MODIFY &&
        !userRights?.OWN_SETTINGS_CHANGE_PASSWORD,
      icon: <SettingsIcon fontSize="small" />,
      onSelect: () => {
        redirectTo('/settings');
      },
    },
    {
      value: 'logout',
      title: t`Log out`,
      icon: <LogoutIcon fontSize="small" />,
      hidden: customer?.configById['custom.appbar.enabled'] === true,
      onSelect: () => {
        logout();
      },
    },
    {
      value: 'logout-restricted',
      title: t`Local log out`,
      tooltip: t`Log out with keep azure session`,
      icon: <LogoutIcon fontSize="small" />,
      hidden:
        user?.lastAuthType !== 10 ||
        customer?.configById['azure.logout.choice'] !== true ||
        customer?.configById['custom.appbar.enabled'] === true,
      onSelect: () => {
        logout(true);
      },
    },
  ];

  const allowNavigation = Boolean(user);
  const canChangeOwnSetting = userRights && userRights.OWN_SETTINGS_MODIFY;
  const canSelectLanguage =
    (customer?.languages ?? []).length > 1 &&
    (canChangeOwnSetting || user?.manyUsersAccountType);
  const shareLink =
    share?.id && share?.type === 'w'
      ? `/workspaces/${share.id}`
      : share?.id && share?.type === 'o'
      ? `/comments/${share.id}/edit`
      : '#';

  const homeLink =
    userRights && userRights.SHOW_FRONTPAGE
      ? '/home'
      : `/folders/${settings && settings.contentFolderId}`;

  const location = useLocation();
  const innerWidth = useWindowSize().innerWidth;
  const mobile = isMobile(innerWidth);

  return (
    <StyledAppBar $overrides={settings?.theme?.appBar} id="app-bar">
      <SkipToContentLink
        href={`${location.pathname}#maincontent`}
        onClick={e => {
          e.preventDefault();
          const mainContent = document.getElementById('maincontent');
          const focusableElements = mainContent
            ? Array.from(
                mainContent.querySelectorAll<HTMLElement>(
                  'a, button, input, textarea, select, details, [tabindex]:not([tabindex="-1"])'
                )
              ).filter(el => !el.hasAttribute('disabled'))
            : [];
          // focus the first focusable element or just the container
          focusableElements[0]
            ? focusableElements[0].focus()
            : mainContent && mainContent.focus();
        }}
      >
        <Trans>Skip to main content</Trans>
      </SkipToContentLink>
      <Toolbar>
        {drawerEnabled && allowNavigation && (
          <IconButton
            id="menuButton"
            data-test="open-left-drawer"
            aria-label={t`Navigation menu`}
            color="inherit"
            onClick={() => user && toggleDrawer(true)}
            size="large"
          >
            <MenuIcon />
          </IconButton>
        )}
        <div id="backButton" />
        <LogoLink
          to={isJCDecauxUI ? '/' : allowNavigation ? homeLink : shareLink}
          data-test="navigate-to-home"
          color="inherit"
          // If navigation is allowed, the user can exit the public space to home
          publicLink={
            share
              ? allowNavigation
                ? homeLink
                : customer?.publicCartLogoLink
              : undefined
          }
          targetBlank={
            !allowNavigation && Boolean(customer?.publicCartLogoLink)
          }
        >
          <StyledLogo
            src={settings?.theme?.logo}
            alt={`${getLangValue(customer?.namesByLang)} ${t`logo`}`}
          />
        </LogoLink>
        <RightWrapper>
          {userRights?.MAGENTO_ORDER &&
            !mobile &&
            !!magentoLoginUrl &&
            customer?.configById['show.magento.directLink'] !== false && (
              <MagentoLoginLink
                onClick={async () => {
                  window.location.href = magentoLoginUrl as string;
                }}
                data-test={'login-to-magento'}
              >
                <Trans>PunaMusta 24/7</Trans>
              </MagentoLoginLink>
            )}
          {(customer?.languages?.length ?? 0) > 1 &&
            customer?.configById['show.language.select'] !== false &&
            ((innerWidth > 1300 && canSelectLanguage) || !allowNavigation) && (
              <LanguageButton />
            )}
          {allowNavigation && isJCDecauxUI && (
            <StyledJCDHelpButton
              modalType="INTEGRATION/JCDECAUX/HELP_INFO"
              color="inherit"
              aria-label={t`Help`}
            >
              {innerWidth > 1300 && t`I need help`}
              <Help />
            </StyledJCDHelpButton>
          )}
          {allowNavigation && (
            <>
              {innerWidth > 1300 ? (
                <StyledUserButton color="inherit" options={userOptions}>
                  {user?.firstName} {user?.familyName}
                  <UserIcon />
                </StyledUserButton>
              ) : (
                <>
                  {!isJCDecauxUI && (
                    <StyledSearchButton
                      modalType="SEARCH"
                      color="inherit"
                      aria-label={t`Search`}
                    >
                      <SearchIcon />
                    </StyledSearchButton>
                  )}
                </>
              )}
            </>
          )}
          {allowNavigation &&
            !isJCDecauxUI &&
            (userRights?.ORDERS_MAKE || userRights?.MAGENTO_ORDER) && (
              <ShoppingCartButton />
            )}
        </RightWrapper>
      </Toolbar>
    </StyledAppBar>
  );
}

const StyledAppBar = styled(AppBar)<{ $overrides: any }>`
  ${({ $overrides }) =>
    $overrides &&
    `
  && {
    ${
      $overrides.backgroundColor && `background: ${$overrides.backgroundColor};`
    }
    ${$overrides.textColor && `color: ${$overrides.textColor};`}
  }
`}
`;

const StyledLogo = styled.img`
  height: 44px;
  margin-top: 4px;
`;

const RightWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${props => props.theme.spacing(2)};
`;

const StyledUserButton = styled(MenuButton)`
  && {
    margin-left: -12px;
    text-transform: none;

    svg {
      margin-left: 4px;
    }
  }
`;

const StyledSearchButton = styled(ModalButton)`
  && {
    margin-right: -16px;
  }
`;

const StyledJCDHelpButton = styled(ModalButton)`
  && {
    margin-right: -16px;
    margin-left: -12px;
    text-transform: none;

    svg {
      margin-left: 4px;
    }

    &:not(:last-child) {
      margin-right: ${props => props.theme.spacing(2)};
    }
  }
`;

const SkipToContentLink = styled.a`
  position: absolute;
  z-index: 100;
  background: ${({ theme }) => theme.palette.secondary.main};
  padding: 16px;
  color: ${({ theme }) => theme.palette.text.primary};
  text-decoration: none;
  font-size: 14px;
  left: -100%;

  &:focus {
    left: 0;
  }
`;

const MagentoLoginLink = styled.a`
  cursor: pointer;
  &:hover,
  &:focus {
    background-color: rgba(0, 0, 0, 0.04);
  }
  font-weight: 500;
  padding: 7px 6px;
  border-radius: 4px;
  font-size: 0.875rem;
`;

export default Bar;
