import { NewsListItem, NewsObject, NewsPiece } from './types';
import { apiBase } from '~common/api.base';

const extendedApi = apiBase.injectEndpoints({
  endpoints: builder => ({
    fetchNews: builder.query<NewsListItem[], void>({
      query: () => ({ url: '/news', method: 'get' }),
    }),

    fetchNewsPiece: builder.query<NewsPiece, string>({
      query: id => ({ url: `/news/${id}`, method: 'get' }),
    }),

    fetchFrontpageNews: builder.query<NewsListItem[], number | void>({
      query: limit => ({
        url: `/news/frontpage`,
        method: 'get',
        params: { limit: limit ?? 15 },
      }),
    }),

    fetchUnreadNews: builder.query<NewsPiece[], string>({
      queryFn: async (userId, _, __, fetch) => {
        // Unread news depends on the current user, so we need to define
        // a customized query where we can run multiple requests based on
        // the return value of another
        const unreadNews = await fetch({
          url: `/users/${userId}/unreadNews?include=object`,
          method: 'get',
        });

        if (unreadNews.error) throw unreadNews.error;

        const newsResponse = unreadNews.data
          ? await Promise.all(
              (unreadNews.data as NewsObject[]).map(newsPiece =>
                'object' in newsPiece
                  ? { data: newsPiece.object }
                  : fetch({ url: `/news/${newsPiece.id}`, method: 'get' })
              )
            )
          : [];

        if (newsResponse.some(responseItem => responseItem.error)) {
          return {
            error: newsResponse.find(responseItem => responseItem.error) as {
              status?: number | undefined;
              data?: any;
            },
          };
        } else {
          const newsData = newsResponse.map(newsItem => newsItem.data);
          return { data: newsData as NewsPiece[] };
        }
      },
    }),

    markAsRead: builder.mutation<NewsPiece, string>({
      query: id => ({ url: `/news/${id}/read`, method: 'put' }),
    }),
  }),
});

export const {
  useFetchNewsQuery,
  useFetchNewsPieceQuery,
  useFetchFrontpageNewsQuery,
  useFetchUnreadNewsQuery,
  useMarkAsReadMutation,
} = extendedApi;
