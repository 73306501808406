import {
  Element,
  ElementCrop,
  ElementLocation,
  Product,
  ProductElement,
  ProductPageThumbnail,
} from './types';
import {
  createActionTypes,
  createAction,
  handleActions,
} from '~utils/ducks.utils';

export interface ProductState {
  product: Product | null;
  pagesById: Record<number, ProductPageThumbnail & { templatePageId: number }>;
  pageTemplatesById: Record<number, ProductPageThumbnail>;
  elementsById: Record<string, ProductElement>;
  selectedPageId: number;
  selectedElementId?: string | null;
  hoveredElementId?: string | null;
  refreshCountersById: Record<string, number>;
  loadingElementIds: string[];
  pagesLoading: true | number[];
  metaUpdaterElements: Record<string, string[]>;
  metaLanguage: string | null;
}

const initialState: ProductState = {
  product: null,
  pagesById: {},
  pageTemplatesById: {},
  elementsById: {},
  selectedPageId: -1,
  selectedElementId: null,
  hoveredElementId: null,
  refreshCountersById: {},
  loadingElementIds: [],
  pagesLoading: true,
  metaUpdaterElements: {},
  metaLanguage: null,
};

const ProductActions = createActionTypes('PRODUCTS', [
  'SHOW_PRODUCT',
  'READ_PRODUCT',
  'AFTER_READ_PRODUCT',
  'AFTER_READ_PAGES',
  'AFTER_READ_PAGE_TEMPLATES',
  'AFTER_READ_ELEMENTS',
  'SET_META_ELEMENTS',

  // Product
  'RENAME_PRODUCT',
  'UPDATE_IMAGE_HEIGHT',
  'SET_META_LANGUAGE',
  'UPDATE_PRODUCT_META_LANG',
  'GENERATE_PREVIEWS',

  // Pages
  'SET_PAGES_BY_ID',
  'SET_PAGES_LOADING',
  'SELECT_PAGE',
  'SELECT_NTH_PAGE',
  'ADD_PAGE',
  'DELETE_PAGE',
  'UPDATE_PAGE_ORDER',
  'AFTER_PAGE_CRUD',

  // Elements
  'SELECT_ELEMENT',
  'HOVER_ELEMENT',
  'UPDATE_ELEMENT',
  'UPDATE_LOCATION',
  'AFTER_UPDATE_ELEMENT',
  'ADD_TO_LOADING_ELEMENTS',
  'RESET_LOADING_ELEMENTS',
  'READ_ELEMENT_AVAILABLE_OPTIONS',

  'CROP_ELEMENT',
  'AFTER_CROP_ELEMENT',
]);

// Actions
const actions = {
  // Read data
  showProduct: createAction(
    ProductActions.SHOW_PRODUCT,
    (productId: string, type: 'product') => ({
      productId,
      type,
    })
  ),
  afterReadProduct: createAction(
    ProductActions.AFTER_READ_PRODUCT,
    (product: Product) => ({ product })
  ),
  afterReadPages: createAction(
    ProductActions.AFTER_READ_PAGES,
    (pagesById: ProductState['pagesById']) => ({
      pagesById,
    })
  ),
  afterReadPageTemplates: createAction(
    ProductActions.AFTER_READ_PAGE_TEMPLATES,
    (pageTemplatesById: ProductState['pageTemplatesById']) => ({
      pageTemplatesById,
    })
  ),
  afterReadElements: createAction(
    ProductActions.AFTER_READ_ELEMENTS,
    (elementsById: ProductState['elementsById']) => ({ elementsById })
  ),
  setMetaElements: createAction(
    ProductActions.SET_META_ELEMENTS,
    (metaUpdaterElements: ProductState['metaUpdaterElements']) => ({
      metaUpdaterElements,
    })
  ),

  // Product
  updateImageHeight: createAction(
    ProductActions.UPDATE_IMAGE_HEIGHT,
    (imageHeight: number) => ({ imageHeight })
  ),
  setMetaLanguage: createAction(
    ProductActions.SET_META_LANGUAGE,
    (language: string) => ({
      language,
    })
  ),
  updateProductMetaLang: createAction(
    ProductActions.UPDATE_PRODUCT_META_LANG,
    (productId: string, product: Product) => ({ productId, product })
  ),
  generatePreviews: createAction(
    ProductActions.GENERATE_PREVIEWS,
    (params: { productId: string } | { nodeId: string }) => params
  ),

  // Pages
  setPagesById: createAction(
    ProductActions.SET_PAGES_BY_ID,
    (pagesById: ProductState['pagesById']) => ({
      pagesById,
    })
  ),
  setPagesLoading: createAction(
    ProductActions.SET_PAGES_LOADING,
    (pagesLoading: ProductState['pagesLoading']) => ({ pagesLoading })
  ),
  selectPage: createAction(
    ProductActions.SELECT_PAGE,
    (selectedPageId: ProductState['selectedPageId']) => ({
      selectedPageId,
    })
  ),
  selectNthPage: createAction(
    ProductActions.SELECT_NTH_PAGE,
    (selectedPageIndex: number) => ({ selectedPageIndex })
  ),
  addPage: createAction(ProductActions.ADD_PAGE, (templatePageId: number) => ({
    templatePageId,
  })),
  deletePage: createAction(ProductActions.DELETE_PAGE, (pageId: number) => ({
    pageId,
  })),
  updatePageOrder: createAction(
    ProductActions.UPDATE_PAGE_ORDER,
    (indexFrom: number, indexTo: number) => ({ indexFrom, indexTo })
  ),
  afterPageCrud: createAction(
    ProductActions.AFTER_PAGE_CRUD,
    (productId: string, page: number) => ({ productId, page })
  ),

  // Elements
  selectElement: createAction(
    ProductActions.SELECT_ELEMENT,
    (elementId: ProductState['selectedElementId']) => ({
      elementId,
    })
  ),
  hoverElement: createAction(
    ProductActions.HOVER_ELEMENT,
    (elementId: ProductState['hoveredElementId']) => ({
      elementId,
    })
  ),
  updateElement: createAction(
    ProductActions.UPDATE_ELEMENT,
    (elementId: string, element: Element, origin?: 'sidebar') => ({
      elementId,
      element,
      origin,
    })
  ),
  updateLocation: createAction(
    ProductActions.UPDATE_LOCATION,
    (elementId: string, newLocation: ElementLocation) => ({
      elementId,
      newLocation,
    })
  ),
  afterUpdateElement: createAction(
    ProductActions.AFTER_UPDATE_ELEMENT,
    (pageIds: number[]) => ({ pageIds })
  ),
  addToLoadingElements: createAction(
    ProductActions.ADD_TO_LOADING_ELEMENTS,
    (elementIds: string[]) => ({ elementIds })
  ),
  resetLoadingElements: createAction(
    ProductActions.RESET_LOADING_ELEMENTS,
    () => ({})
  ),
  readElementAvailableOptions: createAction(
    ProductActions.READ_ELEMENT_AVAILABLE_OPTIONS,
    (elementId: string) => ({ elementId })
  ),

  cropElement: createAction(
    ProductActions.CROP_ELEMENT,
    (elementId: string) => ({
      elementId,
    })
  ),
  afterCropElement: createAction(
    ProductActions.AFTER_CROP_ELEMENT,
    (elementId: string, crop: ElementCrop) => ({ elementId, crop })
  ),
};

// Reducer
export default handleActions(initialState)
  .handle(actions.showProduct, state => ({
    ...initialState,
    // This is no longer read after showProduct
    product: state.product,
    // Refresh all pages
    refreshCountersById: Object.keys(state.refreshCountersById).reduce(
      (obj, id) => ({
        ...obj,
        [id]: state.refreshCountersById[id] + 1,
      }),
      {}
    ),
  }))
  .handle(actions.afterReadProduct, (state, action) => ({
    ...state,
    product: {
      ...action.payload.product,
      wysiwygSettings: {
        superscriptPosition: 0.33,
        superscriptHeight: 0.583,
        subscriptPosition: 0.33,
        subscriptHeight: 0.583,
        ...action.payload.product.wysiwygSettings,
      },
    },
  }))
  .handle(actions.afterReadPages, (state, action) => ({
    ...state,
    pagesById: action.payload.pagesById,
    refreshCountersById: Object.keys(action.payload.pagesById).reduce(
      (obj, id) => ({
        ...obj,
        [id]:
          (state.pagesById[id] &&
            state.pagesById[id].id === state.pagesById[id].pageNumber &&
            state.refreshCountersById[id]) ||
          new Date().getTime() - 1585063606284,
      }),
      {}
    ),
    product: state.product && {
      ...state.product,
      // update product's page number
      pages: Math.max(
        ...Object.keys(action.payload.pagesById).map(x => Number.parseInt(x))
      ),
    },
    // preserve the selected page
    selectedPageId:
      state.selectedPageId > 0 && state.pagesById
        ? state.pagesById[state.selectedPageId]?.pageNumber
        : state.selectedPageId,
  }))
  .handle(actions.afterReadPageTemplates, (state, action) => ({
    ...state,
    pageTemplatesById: action.payload.pageTemplatesById,
  }))
  .handle(actions.afterReadElements, (state, action) => ({
    ...state,
    elementsById: action.payload.elementsById,
  }))
  .handle(actions.setMetaElements, (state, action) => ({
    ...state,
    metaUpdaterElements: action.payload.metaUpdaterElements,
  }))
  .handle(actions.updateImageHeight, (state, action) => ({
    ...state,
    product: state.product && {
      ...state.product,
      imageHeight: action.payload.imageHeight,
    },
  }))
  .handle(actions.setMetaLanguage, (state, action) => ({
    ...state,
    metaLanguage: action.payload.language,
  }))
  .handle(actions.selectElement, (state, action) => ({
    ...state,
    elementsById: {
      ...state.elementsById,
      ...(action.payload.elementId
        ? {
            [action.payload.elementId]: {
              ...state.elementsById[action.payload.elementId],
              updatedFrom: undefined,
            },
          }
        : {}),
    },
    selectedElementId: action.payload.elementId,
  }))
  .handle(actions.hoverElement, (state, action) => ({
    ...state,
    hoveredElementId: action.payload.elementId,
  }))
  .handle(actions.updateElement, (state, action) => {
    if (!state.elementsById[action.payload.elementId]) return state;
    return {
      ...state,
      elementsById: {
        ...state.elementsById,
        [action.payload.elementId]: {
          ...state.elementsById[action.payload.elementId],
          element: action.payload.element,
          updatedFrom: action.payload.origin,
        },
      },
    };
  })
  .handle(actions.updateProductMetaLang, (state, action) => {
    if (!action.payload.productId) return state;
    return {
      ...state,
      product: action.payload.product,
    };
  })
  .handle(actions.updateLocation, (state, action) => {
    if (!state.elementsById[action.payload.elementId]) return state;
    return {
      ...state,
      elementsById: {
        ...state.elementsById,
        [action.payload.elementId]: {
          ...state.elementsById[action.payload.elementId],
          element: {
            ...state.elementsById[action.payload.elementId].element,
            location: {
              ...state.elementsById[action.payload.elementId].element.location,
              ...action.payload.newLocation,
            },
          },
        },
      },
    };
  })
  .handle(actions.afterUpdateElement, (state, action) => ({
    ...state,
    refreshCountersById: {
      ...state.refreshCountersById,
      ...action.payload.pageIds.reduce(
        (a, id) => ({ ...a, [id]: state.refreshCountersById[id] + 1 }),
        {}
      ),
    },
  }))
  .handle(actions.addToLoadingElements, (state, action) => ({
    ...state,
    loadingElementIds: [
      ...state.loadingElementIds,
      ...action.payload.elementIds,
    ],
  }))
  .handle(actions.setPagesLoading, (state, action) => ({
    ...state,
    pagesLoading: action.payload.pagesLoading,
  }))
  .handle(actions.resetLoadingElements, state => ({
    ...state,
    loadingElementIds: [],
  }))
  .handle(actions.setPagesById, (state, action) => ({
    ...state,
    pagesById: action.payload.pagesById,
  }))
  .handle(actions.selectPage, (state, action) => ({
    ...state,
    selectedPageId: action.payload.selectedPageId,
    selectedElementId: null,
    elementsById: {},
  }));

// Bundle things in a model
export const products = {
  actions,
  selector: {},
};
