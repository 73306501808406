import {
  PROPERTY_ARCHIVE_DESTINATION_PATH,
  PROPERTY_ARCHIVE_ORIGINAL_PATH,
  PROPERTY_ARCHIVE_SCHEDULED,
  PROPERTY_ARCHIVE_SCHEDULE_TIME,
} from 'src/content/common/utils';
import { UploadStatus } from './content.constants';
import { TreeNode } from './content.utils';
import { WorkflowSettings, WorkflowSettingsBase } from './workflows/types';
import { NonStrict, TranslatedData } from './common.types';
import { Product } from './product.types';

import { DndType } from '~common/misc/drag/constants';

export interface SearchSuggestionItem {
  fieldId: string;
  value: string;
  count: number;
}

export interface SearchSuggestion {
  id: string;
  items: SearchSuggestionItem[];
  title: string;
}

export interface SearchTag {
  id: string;
  value?: string;
  title?: string;
}

export interface MetaFieldTreeNode {
  id: string;
  namesByLang: Record<string, string>;
  parentId?: string;
  sortIndex: number;
  childrenIds: string[];
}

export interface MetaFieldTree {
  id: string;
  namesByLang: Record<string, string>;
  rootItemId: string;
  itemsById: Record<string, MetaFieldTreeNode>;
}

export interface ArchiveNode {
  fileType: 'nt:archiveFolder';
  id: string;
  name: string;
  namesByLang: Record<string, string>;
}

export interface ArchiveTree {
  childCount: Number;
  children: ArchiveTree[];
  depth: Number;
  node: ArchiveNode;
}

interface BaseMetaField {
  id: string;
  namesByLang: TranslatedData;
  type: 'unspecified' | 'file-meta' | 'workflow';
  placement: number;
  isLocalized: boolean;
  isMandatory: boolean;
  isReadOnly: boolean;
  isVisible: boolean;
  isTranslation: boolean;
  isCopy: boolean;
  position: {
    search: number;
    searchField: number;
    result?: number;
    searchResult?: number;
    card?: number;
  };
  settings?: NonStrict;
  autocomplete: boolean;
  infoTextByLang?: TranslatedData;
}

interface BasicMetaField extends BaseMetaField {
  valueType:
    | 'text'
    | 'textarea'
    | 'numeric'
    | 'email'
    | 'emails'
    | 'separator'
    | 'date'
    | 'wysiwyg';
}

interface DropdownMetaField extends BaseMetaField {
  valueType: 'dropdown' | 'dropdown-multiple';
  optionsByLang: TranslatedData<string[]>;
}

export const isDropdownMetaField = (
  field: MetaField
): field is DropdownMetaField =>
  field.valueType === 'dropdown' || field.valueType === 'dropdown-multiple';

export interface TreeMetaField extends BaseMetaField {
  valueType: 'tree' | 'tree-multiple';
  valueTree: MetaFieldTree;
  tree: TreeNode;
  nodesById: Record<string, TreeNode>;
}

export interface DateRangeField extends BaseMetaField {
  valueType: 'date-range';
  fromDateFieldId: string;
  toDateFieldId: string;
}

export interface ArchiveField extends BaseMetaField {
  valueType: 'archive';
  scheduledFieldId: typeof PROPERTY_ARCHIVE_SCHEDULED;
  scheduleTimeFieldId: typeof PROPERTY_ARCHIVE_SCHEDULE_TIME;
  originalPathFieldId: typeof PROPERTY_ARCHIVE_ORIGINAL_PATH;
  destinationPathFieldId: typeof PROPERTY_ARCHIVE_DESTINATION_PATH;
}

export const isTreeMetaField = (field: MetaField): field is TreeMetaField =>
  field.valueType === 'tree' || field.valueType === 'tree-multiple';

export type MetaField =
  | BasicMetaField
  | DropdownMetaField
  | TreeMetaField
  | DateRangeField
  | ArchiveField;

export type MetaData = string | string[] | Date | null;

export interface Thumbnail {
  id: number;
  thumbnailUuid: string;
  previewUuid: string;
}

type ContentType =
  | 'folder'
  | 'file'
  | 'cart'
  | 'campaign'
  | 'masterProduct'
  | 'userProduct'
  | 'shopping'
  | 'externalFile';

export type ConcreteFileType = `nt:${ContentType}`;
export type FileType =
  | ConcreteFileType
  | 'nt:linkedFile'
  | 'nt:linkedFolder'
  | 'nt:archiveFolder'
  | 'nibo:attachment'
  | 'nibo:attachments'
  | 'nibo:versionHistory'
  | 'nibo:version'
  | 'nibo:metadata'
  | 'nt:resource'
  | 'nt:image'
  | 'nt:text'
  | 'nt:font-family'
  | 'nt:font-file';

/** Represents a location of the node in the JCR tree, which might be a link to another node.
 *
 * This location and id should be used in most cases
 * except when accessing/updating asset's properties or metadata.
 * Especially, use this id when doing something that depends on the location of the asset,
 * such as things involving rights or attachments.
 */
export interface Node {
  id: string;
  parentId: string;
  path: string;
  parents?: BaseFile[];
  fileType: FileType;
  isLink: boolean;
  inCart: boolean;
  inShoppingCart: boolean;
  isShared: boolean;
  created: string;
}

/** Represents the location of the asset in the backend's JCR tree, i.e. concrete node. The endpoint of links.
 *
 * Use this location and id to access and/or update the asset's properties and metadata.
 */
export type ConcreteNode = Omit<
  Node,
  'parents' | 'isLink' | 'inCart' | 'inShoppingCart' | 'isShared'
>;

// TODO: make complete
/** An asset, that can be a file, folder, workspace/cart, userProduct, etc.
 *
 * Properties, other than `node` and `concrete`, should refer to the properties of the asset,
 * however, this might depend on the endpoint used to fetch the data.
 * `node` and `concrete` represent asset's location in the JCR tree.
 * `node` is the location that was used to access the asset, i.e. a link to the asset or the concrete asset.
 * `concrete` is the location of the asset.
 *
 * NOTE: Rights and some of the settings depend of the location of the asset,
 * and therefore can vary whether we are accessing through a link or the asset directly.
 */
export interface CustomerSpecificActionRight {
  sendToNeo?: boolean;
  sendToBold?: boolean;
}

export type UserRights = CustomerSpecificActionRight & {
  addToWorkspace: boolean;
  download: boolean;
  view: boolean;
  versions?: boolean;
  move?: boolean;
  copy?: boolean;
  link?: boolean;
  share?: boolean;
  edit?: boolean;
  editRights?: boolean;
  customize?: boolean;
  moveInto?: boolean;
  copyInto?: boolean;
  linkInto?: boolean;
  commentRequest?: boolean;
  addFiles?: boolean;
  order?: boolean;
  sendToSynkka?: boolean;
  archiveInSynkka?: boolean;
  removeFromSynkka?: boolean;
  publicShare?: boolean;
  subtitles?: boolean;
  crop?: boolean;
  delete?: boolean;
  deleteFiles?: boolean;
  addFolder?: boolean;
  addWorkspace?: boolean;
  publicLink?: boolean;
  listenAudio?: boolean;
  workflowsManage?: boolean;
  archive?: boolean;
  restoreArchived?: boolean;
};

export interface File {
  id?: string;
  /** Properties of the node */
  node: Node;
  /** Properties of the concrete node */
  concrete?: ConcreteNode;
  name: string;
  namesByLang: TranslatedData<string>;
  fullNamesByLang: TranslatedData<string>;
  uploadInstructionsByLang: TranslatedData<string>;
  descriptionsByLang: TranslatedData<string>;
  instructionsByLang?: TranslatedData<string>;
  instructionImageUuid?: number;
  isFile: boolean;
  isFolder: boolean;
  isCart: boolean;
  isMasterProduct: boolean;
  isUserProduct: boolean;
  /** Contains the asset's metavalues which are customer specific.
   * Use `useCustomerMetaFields()` to get the related field descriptions. */
  metaValuesById: Record<string, TranslatedData<string>>;
  previewStatus?: 'GENERATING' | 'EXISTS' | 'UNAVAILABLE';
  contentStatus?: 'GENERATING' | 'EXISTS';
  imageMetaStatus?: 'GENERATING' | 'EXISTS' | 'UNAVAILABLE';
  analyzeStatus?: 'ANALYZING' | 'ANALYZED' | 'UNAVAILABLE';
  apiLink: string;
  apiContentLink: string;
  apiPreviewLink?: string;
  url: string;
  fileSize?: number;
  createdBy?: BaseUser;
  accessedBy?: BaseUser;
  modified: string;
  /** A dump of all the system properties of the asset.
   * The most important are already parsed to other fields. */
  propertiesById: Record<string, string>;
  customUploadLayout?: UploadLayout;
  inheritedCustomUploadLayout?: UploadLayout;
  inheritedPropertiesById: Record<string, string>;
  /** folder inherited metadata from up level parent folder */
  inheritedMetaById: Record<string, string>;
  /** folder own metadata that it can inherit to childs */
  inheritableMetaById: Record<string, TranslatedData<string>>;
  /** Depends on the location through which the asset is accessed
   * and is specific to the node rather than the asset. */
  userRights?: UserRights;
  /** The scope in which userRights are defined.
   * Some of the rights might be falsely missing if extended scope is not used. */
  userRightsScope?: {
    public?: boolean;
    extended?: boolean;
    basic?: boolean;
  };
  concreteUserRights?: {
    view: boolean;
  };
  thumbnails?: Thumbnail[];
  isInherited?: boolean;
  altTextsByLang: TranslatedData;
  mimeGroup?: string;
  allowedShareOut?: boolean;
  textContent?: string;
  indicateSynkka?: boolean;
  workflows?: WorkflowSettingsBase[];
  variants?: FileVariant[];
  validAmounts?: FileAmountOption[];
  categoryIds?: string[];
  publicTicket?: string;
  /** NOTE: Whether the attachment can be downloaded or not (the `downloadable` property) depends on the nodes location.
   * Make sure that you access this property through the location of the node (link or asset); that is, use `itemsById`; if the downloadability matters. */
  attachments?: FileAttachment[];
  removed: false;
  emailCount?: number;
  emails?: ShareHistory[];
  /** Number of direct child assets; folders and carts are excluded */
  fileCount?: number;
  /** Number of direct child assets and folders; carts are excluded */
  fileCountAll?: number;
  versions?: Version[];
  versionLabel?: string;
  metaById?: Record<string, MetaData>;
  /** Whether the node in processing or not */
  inProcessing?: boolean;
  /** Whether the node in long unexpected processing or not */
  longProcessing?: boolean;
  /** Whether the node preview image in processing or not */
  previewInProcessing?: boolean;
  /** Information about sharing if `File` is a workspace */
  workspaceShare?: WorkspaceShare;
  /** The individual links of the current File */
  linkedFiles?: File[];
  /** The id of the parent of the link, that is, the folder id of where this link exists */
  linkParentId?: string;
  /** Optional Indox-link returned by the API, if one is available. */
  indoxLink?: string;
  /** Optional property indicating whether or not this file is available via CDN */
  isCDN?: boolean;
  /** Synkka processing status */
  synkkaInProcessing?: boolean;
  /** The current user has marked the material as a favorite */
  isLikedByUser?: boolean;
}

export type SynkkaFile = File & {
  /** False if the synkka file is not attached to the product in Synkka */
  externalNodeSynkka: boolean;
  /** Readable error message */
  synkkaErrorMessage: string;
  /** Readable warning message */
  synkkaWarningMessage: string;
  /** Readable Synkka status */
  synkkaStatus: string;
  /** Datetime when the file was sent to Synkka */
  sendDate: Date;
  /** File ID in Synkka */
  synkkaId: number;
  /** Filename in Synkka */
  synkkaFilename: string;
  /** User ID of the Content HUB user who sent the file */
  senderId: number;
  /** Sender user {firstName lastName, username} */
  senderName: string;
};

export type TaskStatus = {
  status: string;
  executed?: string;
};

export type SynkkaCriteria = {
  sortBy:
    | 'id'
    | 'name'
    | 'path'
    | 'external_node_synkka'
    | 'sender_username'
    | 'synkka_filename'
    | 'send_date'
    | 'synkka_error_message'
    | 'synkka_warning_message'
    | 'synkka_status';
  sortDir: 'asc' | 'desc';
  page: number;
  pageSize: number;
  synkkaStatus: 'all' | 'added' | 'not_added' | 'archived' | 'out_of_sync';
};

/** A single file version */
export type Version = {
  id: number;
  created: string;
  creator: string;
  label: string;
  fileName?: string;
  propertiesById?: Record<string, string>;
  metaById?: Record<string, string>;
  inProgress: boolean;
};

export function fileExists(file: File | RemovedFile | undefined): file is File {
  return !!(!file?.removed && file);
}

/** Extend ShareHistory type with object including permissions for removal of share */
export type ShareHistoryUserRights = {
  close: true | null;
};

/** A single item in a list of emails sent out when sharing a workspace */
export type ShareHistory = {
  id: number;
  sentTime: string;
  mailType: 5;
  fromEmail: string;
  toEmail: string;
  subject: string;
  removed?: boolean;
  userRights: ShareHistoryUserRights;
};

export type RemovedFile = {
  id: string;
  removed: true;
  removeTime: string;
};

/** Most API endpoints might return a `RemovedFile` instead of a `File` */
export type Fileish = File | RemovedFile;

/** Items returned from the '/contents' endpoint a.k.a. Lucene Index */
export interface Content {
  alt?: string;
  apiContentLink: string;
  url: string;
  apiLink: string;
  archive: boolean;
  created: string;
  description?: string;
  emails?: string[];
  emailCount?: string[];
  entryType: 'concrete' | 'link';
  fileType: ContentType;
  folder: boolean;
  hasPublicSharingValidityPeriod: boolean;
  id: string;
  indicateSynkka?: boolean;
  language: string;
  location: 'undefined' | 'material' | 'cart' | 'archive' | 'user';
  mimeGroup?: MimeGroup;
  mimeType: MimeType;
  modified: string;
  name: string;
  object?: File;
  owner?: string;
  parentId: string;
  publiclySharedStartTime?: string;
  publiclySharedEndTime?: string;
  score: number;
  sharing: ShareStatus;
  type:
    | 'customer'
    | 'user'
    | 'group'
    | 'material'
    | 'news'
    | 'order'
    | 'offer'
    | 'comment'
    | 'external'
    | 'externalFile';
  /** A partial metaById, will only contain  */
  metaById?: Record<string, string>;
  isShared: boolean;
  thumbnails?: Thumbnail[];
  isLikedByUser?: boolean;
}

export type WorkspaceShare = {
  sharing: ShareStatus;
  hasPublicSharingValidityPeriod: boolean;
  publiclySharedStartTime?: string;
  publiclySharedEndTime?: string;
  ownerId?: number;
};

export interface WorkspaceContent
  extends Content,
    Omit<WorkspaceShare, 'ownerId'> {
  owner?: string;
}

type ItemContainer<Key extends string, ContentType> = {
  status: string | null;
  timestamp: Date;
} & Record<Key, ContentType | undefined>;

export type FileContainer = ItemContainer<'file', File>;
export type FolderContainer = ItemContainer<'folder', File>;
export type WorkflowSettingContainer = ItemContainer<
  'workflow',
  WorkflowSettings
>;
export type RemovedItemContainer = ItemContainer<'item', RemovedFile>;
export type ProductContainer = ItemContainer<'product', Product>;

export type FilesById = Record<string, FileContainer | undefined>;
export type FoldersById = Record<string, FolderContainer | undefined>;
export type WorkflowSettingsById = Record<
  string,
  WorkflowSettingContainer | undefined
>;
export type RemovedItemsById = Record<string, RemovedItemContainer | undefined>;
export type ProductsById = Record<string, ProductContainer | undefined>;

export interface FolderTreeEntry {
  node: BaseFile & {
    createdBy?: BaseUser;
  };
  depth: number;
  children: FolderTreeEntry[];
}

export type MimeGroup =
  | 'picture'
  | 'video'
  | 'audio'
  | 'adobe'
  | 'cad'
  | 'text'
  | 'presentation'
  | 'brochures';
export type MimeType =
  | 'text/plain'
  | 'image/jpeg'
  | 'image/gif'
  | 'image/tiff'
  | 'image/png'
  | 'image/psd'
  | 'application/postscript'
  | 'application/pdf'
  | 'application/dxf'
  | 'unknown'
  | 'image/svg+xml';

type ExtendWithId<T extends string> = T extends `${infer C}.`
  ? `${C}.${number}`
  : T;
export type FileAttachmentType =
  | 'original'
  | 'preview'
  | 'thumbnail'
  | 'pdflow'
  | 'pdflink'
  | 'attachment.'
  | 'videoAttachment.'
  | 'customerAttachment.'
  | 'indox';
export type AttachmentSizeInfo = {
  bytes: number;
  value: string;
  unit: string;
};
export type FileAttachmentId = ExtendWithId<FileAttachmentType>;
export interface FileAttachment {
  id: FileAttachmentId;
  type: FileAttachmentType;
  profileUsage: number;
  publicTicket: string;
  publicLink?: string;
  namesByLang: TranslatedData;
  sizeInfo?: AttachmentSizeInfo;
  propertiesById: Record<string, string>;
  /** NOTE: this depends of the node's location and therefore can change depending on
   * if we are accessing the asset through a link or directly. */
  downloadable?: boolean;
}

/** Contains only the most essential and basic properties of a File */
export interface BaseFile {
  id: string;
  fileType: FileType;
  name: string;
  namesByLang: TranslatedData<string>;
}

export interface FileVariant {
  id: string;
  name: string;
  price: number;
  placement: number;
}

export interface FileAmountOption {
  amount: number;
  recipientId: string;
  price: number;
  currency: string;
}

/** Only the bare properties of user. Used to describe File's creator, modifyer and accessor. */
export interface BaseUser {
  username: string;
  firstName: string;
  familyName: string;
  company: string;
  customer_id: string; // eslint-disable-line camelcase
}

export interface InfoText {
  id: number;
  imageUuid: number;
  contents: TranslatedData;
}

export type UploadFile = {
  id: string;
  blob: Blob;
  preview: string;
  name: string;
  originalName?: string;
  type: string;
  subtype: string;
  uploadStatus?: UploadStatus;
  uploadProgress?: number;
  exists?: boolean;
  existingId?: string;
  versioning?: boolean | undefined;
};

export interface UploadProgress {
  uploaded: number;
  size: number;
  remaining?: number;
}

export type UploadLayout = '0' | '1' | '2' | '3' | '4' | '5' | '6' | '7';

// /////////////////// //
// Object rights types //
// /////////////////// //

/**
 * Following keys always correspond to same object rights, for example
 * 1 -> read, 2 -> edit, etc.
 */
export enum ObjectRightKey {
  READ = 1,
  EDIT_OBJECT = 2,
  EDIT_RIGHTS = 4,
  REMOVE_OBJECT = 16,
  ADD_CONTENT = 64,
  EDIT_CONTENT = 128,
  REMOVE_CONTENT = 256,
  MAKE_ORDER = 512,
  MAKE_OFFER_REQUEST = 1024,
  MAKE_COMMENT_REQUEST = 4096,
}

export type ObjectRights = {
  [key in ObjectRightKey]: {
    id: number;
    selected: boolean;
    partiallySelected: boolean;
    disabled: boolean;
  };
};

export type GroupRightsById = {
  [groupId: string]: {
    id: number;
    description?: string;
    operationIds?: string;
    rights: ObjectRights;
  };
};

// Types for `/folders/<id>/files/tree` endpoints

export type TreeResponseNode = {
  node: {
    id: string;
    name: string;
    fileType: FileType;
    namesByLang: TranslatedData;
    userRights: ObjectRightKey[] | null;
    rights: GroupRightsById | null;
    createdBy: BaseUser | null;
  };
  depth: number;
  children: TreeResponseNode[];
};

export interface RightsTreeResponseNode
  extends Omit<TreeResponseNode, 'children'> {
  childCount: number;
  children?: RightsTreeResponseNode[];
}

export enum ShareStatus {
  /** Currently available only to the owner of the material */
  PERSONAL_AND_UNSHARED = '1',
  /** Available only to a specific group of users of the service */
  GROUP_AND_UNSHARED = '2',
  /** Available to a group of users and also via link/mail share */
  GROUP_AND_PUBLIC = '3',
  /** Available to the owner of the material and also via link/mail share */
  PERSONAL_AND_PUBLIC = '4',
}

export const personalShare = [
  ShareStatus.PERSONAL_AND_PUBLIC,
  ShareStatus.PERSONAL_AND_UNSHARED,
];

export const publicShare = [
  ShareStatus.PERSONAL_AND_PUBLIC,
  ShareStatus.GROUP_AND_PUBLIC,
];

export const groupShare = [
  ShareStatus.GROUP_AND_PUBLIC,
  ShareStatus.GROUP_AND_UNSHARED,
];

export type DragSource = 'sidebar' | 'shopping';

export type DndItem = {
  type: DndType;
  itemIds: string[];
  item: File;
  name: string;
  showCustomDragLayer: boolean;
  /** We only want to show delete dropzone if dragging from sidebar or shopping cart */
  dragSource?: DragSource;
};

export type HandleFileConflict = 'override' | 'rename' | 'default' | 'skip';
