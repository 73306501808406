import React, { Component } from 'react';
import styled from 'styled-components';
import { find, has } from 'lodash';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { Trans } from '@lingui/macro';
import useApi from './use.api';
import fileContentApi from './fileContent.api';
import Picture from './Picture';
import SideBar from './SideBar';
import UseSelection from './UseSelection';

import ErrorBoundary from '~utils/ErrorBoundary.tsx';
import {
  ModalWrapper,
  ModalBar,
  ModalButtonBar,
  ModalContent,
  ModalCloseButton,
} from '~common/sections/Modal';
import Typography from '~misc/Typography';
import Button from '~inputs/Button';
import LoadableButton from '~common/inputs/LoadableButton';

class CropContainer extends Component {
  static propTypes = {
    customerId: PropTypes.string.isRequired,
    nodeId: PropTypes.string.isRequired,
    closeModal: PropTypes.func.isRequired,
    use: PropTypes.object,
    hideSidebar: PropTypes.bool,
    download: PropTypes.bool,
    onDownload: PropTypes.func,
    onCrop: PropTypes.func,
    allowOverflow: PropTypes.bool,
    hideAddMaterialButton: PropTypes.bool,
    hideDownload: PropTypes.bool,
    onAddMaterial: PropTypes.func,
    nameConfigId: PropTypes.number,
  };

  static defaultProps = {
    download: true,
  };

  // Pixel units here correspond to the preview image used in the
  // cropper component, NOT the actual source image for convenience.
  // However, the values showed in the UI, that is, in the <Dimensions>
  // component, are scaled to represent the source image.
  state = {
    uses: [],
    use: null,
    useId: null,
    crop: {
      x1: null,
      y1: null,
      x2: null,
      y2: null,
    },
    // Size of the cropped area. Changing this manipulates the crop component
    // directly. Dragging the crop area adjusts this value. So there's a 2-way
    // communication between the states.
    size: {
      x: null,
      y: null,
    },
    picturePreviewSize: {
      x: null,
      y: null,
    },
    // Original here means the source image. It can be a conversion, so it's not
    // necessarily the version of the image called 'original'.
    pictureOriginalSize: {
      x: null,
      y: null,
    },
    // Preview dimensions * scale = Source dimensions
    scale: null,
    // These are the pixel dimensions the cropped area will be scaled into.
    // This is generally in the same aspect ratio as the scaled area, but it
    // can be overridden in a free selection mode. See unlockedFinalSizeRatio
    finalSize: {
      x: null,
      y: null,
    },
    // If the user touches the size input, the final size will be considered
    // manual, and the system won't alter it automatically thereafter.
    manualFinalSize: false,
    unlockedFinalSizeRatio: false,
    conversionData: [],
    // There might be an use that requires a conversion that doesn't exist
    // So we hide those
    availableConversions: [],
    isDownloading: false,
    /**
     * Whether this image is being downloaded for it to be added as a new file
     */
    isProcessing: false,
    /** Whether image's preview is still being generated */
    previewLoading: true,
  };

  async componentDidMount() {
    // if use is defined, skip the use selection
    if (this.props.use) this.setState({ use: this.props.use });
    else await this.fetchUses();
    await this.fetchDimensions();
    // don't get preview dimensions before the preview image is loaded
    if (!this.state.previewLoading) {
      this._getPreviewDimensions(this.props.nodeId, () => {
        if (this.props.use) this.selectUse(this.props.use);
      });
    }
  }

  async componentDidUpdate() {
    if (
      !this.state.previewLoading &&
      this.state.picturePreviewSize.x === null
    ) {
      this._getPreviewDimensions(this.props.nodeId, () => {
        if (this.props.use) this.selectUse(this.props.use);
      });
    }
  }

  onSelectUse = (event, id) => {
    const { uses } = this.state;
    const useId = isNaN(id) ? parseInt(event.target.value, 10) : id;
    const use = find(uses, { id: useId });
    this.selectUse(use);
  };

  selectUse = async use => {
    const { picturePreviewSize } = this.state;
    const pictureOriginalSize = await this._getOriginalDimensions(
      this.state.conversionData,
      use,
      picturePreviewSize
    );
    if (!pictureOriginalSize) return;
    let x;
    let y;
    const initialScale = 0.9; // How big the crop area should be initially
    const initialWidth = initialScale * picturePreviewSize.x;
    // If a ratio is specified
    if (use.proportions && use.proportions.x && use.proportions.y) {
      const ratio = use.proportions.x / use.proportions.y;
      x = initialWidth;
      y = x / ratio;
      // don't grow the cropped area over picture height
      if (y > picturePreviewSize.y) {
        y = picturePreviewSize.y;
        x = y * ratio;
      }
    } else {
      x = initialWidth;
      y = initialScale * picturePreviewSize.y;
    }

    const scale = pictureOriginalSize.x / picturePreviewSize.x;

    const xGap = (picturePreviewSize.x - x) / 2;
    const yGap = (picturePreviewSize.y - y) / 2;

    this.setState({
      useId: use.id,
      use,
      crop: {
        x1: Math.round(xGap),
        y1: Math.round(yGap),
        x2: Math.round(picturePreviewSize.x - xGap),
        y2: Math.round(picturePreviewSize.y - yGap),
      },
      size: { x: Math.round(x), y: Math.round(y) },
      finalSize: { x: Math.round(x * scale), y: Math.round(y * scale) },
      scale,
      manualFinalSize: false,
    });
    if (use.size && use.size.x && use.size.y) {
      this.setState({ finalSize: use.size });
    }
  };

  // Handler for when the crop area is dragged
  onSetCrop = data => {
    const { original } = data;

    const crop = {
      x1: original.x,
      y1: original.y,
      x2: original.x + original.width,
      y2: original.y + original.height,
    };

    const size = {
      x: original.width,
      y: original.height,
    };

    this.setState({ crop, size });
    this._handleFinalSize(original.width, original.height);
  };

  // This gets called when manually adjusting the numbers
  onAdjustCropDimension = data => {
    const x = data.x;
    const y = data.y;
    this.setState({ size: { x, y } });
    this._handleFinalSize(x, y);
  };

  // Same as above
  onAdjustFinalSize = data => {
    const x = Math.min(parseInt(data.x, 10), this.state.pictureOriginalSize.x);
    const y = Math.min(parseInt(data.y, 10), this.state.pictureOriginalSize.y);
    this.setState({
      finalSize: {
        x,
        y,
      },
      manualFinalSize: true,
    });
  };

  onSetSize = size => {
    this.setState({ size });
  };

  getFileData = () => {
    const { nodeId, use, nameConfigId } = this.props;
    const { finalSize, crop, scale } = this.state;

    const scaledCrop = {};
    Object.keys(crop).forEach(key => {
      scaledCrop[key] = Math.round(crop[key] * scale);
    });
    const useContentType = this.getSelectContentType(use);
    return {
      id: nodeId,
      type: useContentType,
      size: { x: finalSize.x, y: finalSize.y },
      crop: scaledCrop,
      params: { nameConfigId },
    };
  };

  onDownload = () => {
    // Download the file and navigate back to preview view
    this.setState({ isDownloading: true });

    fileContentApi.download(this.getFileData());

    // This is bad
    setTimeout(() => {
      this.setState({ isDownloading: false });
      this.props.onDownload();
    }, 2000);
  };

  onToggleFinalSizeRatioLock = () => {
    const currentStatus = this.state.unlockedFinalSizeRatio;
    this.setState({
      unlockedFinalSizeRatio: !currentStatus,
      manualFinalSize: true,
    });
    // If the lock is engaged, automatically transform the final size
    // so it conforms to the aspect ratio of the cropped area.
    if (currentStatus === true) {
      const ratio = this.state.size.x / this.state.size.y;
      this.setState({
        finalSize: {
          x: this.state.finalSize.x,
          y: this.state.finalSize.x / ratio,
        },
      });
    }
  };

  getSelectContentType = use => {
    if (this.state.useId) {
      const useObj = find(this.state.uses, { id: this.state.useId });
      if (useObj) return useObj.contentType;
    }
    return use ? use.contentType : 'original';
  };

  _getPreviewDimensions = (id, callback) => {
    const img = new Image();
    img.onload = () => {
      this.setState(
        {
          picturePreviewSize: { x: img.width, y: img.height },
        },
        callback
      );
    };
    img.src = fileContentApi.getLink({
      id,
      type: 'preview',
      params: {
        ignoreIcon: true,
        time: new Date().getTime(),
      },
    });
  };

  async fetchDimensions() {
    const { nodeId } = this.props;
    const data = await fileContentApi.fetchDimensions({ id: nodeId });

    const conversionIds = data.attachments
      // Some conversions don't have their dimension data in the API, so we
      // can't use those
      .filter(
        attachment =>
          has(attachment, "propertiesById['nibo:image-width']") &&
          has(attachment, "propertiesById['nibo:image-height']")
      )
      .map(attachment => attachment.type);
    this.setState({
      conversionData: data,
      availableConversions: conversionIds,
    });
  }

  _getOriginalDimensions = async (data, use, previewSize) => {
    const original = find(
      data.attachments,
      attachment => attachment.type === use.contentType
    );
    if (!original) return undefined;
    const pictureOriginalSize = {
      x: parseInt(original.propertiesById['nibo:image-width'], 10),
      y: parseInt(original.propertiesById['nibo:image-height'], 10),
    };
    // Check if the dimensions are inverted
    if (
      Math.sign(previewSize.x - previewSize.y) !==
      Math.sign(pictureOriginalSize.x - pictureOriginalSize.y)
    ) {
      const temp = pictureOriginalSize.x;
      pictureOriginalSize.x = pictureOriginalSize.y;
      pictureOriginalSize.y = temp;
    }
    this.setState({ pictureOriginalSize });
    return pictureOriginalSize;
  };

  _handleFinalSize = (width, height) => {
    const { use, manualFinalSize, scale, unlockedFinalSizeRatio } = this.state;
    // Only adjust this if the user hasn't touched it and it's not specified
    // by the use
    if (!(use.size && use.size.x) && !manualFinalSize) {
      const finalX = Math.round(width * scale);
      const finalY = Math.round(height * scale);
      this.setState({ finalSize: { x: finalX, y: finalY } });
    }
    // In free select, if lock is engaged, force the final size to conform to
    // the selection ratio
    if (
      !(use.size && use.size.x) &&
      !(use.proportions && use.proportions.x) &&
      !unlockedFinalSizeRatio
    ) {
      const ratio = width / height;
      if (manualFinalSize) {
        this.setState({
          finalSize: {
            ...this.state.finalSize,
            y: this.state.finalSize.x / ratio,
          },
        });
      }
    }
  };

  async fetchUses() {
    const { customerId } = this.props;
    const data = await useApi.fetch({ customerId });
    this.setState({ uses: data });
  }

  handleConfirmClick = (confirm = true) => {
    const { download, onCrop, closeModal } = this.props;
    if (download) this.onDownload();
    else {
      if (onCrop)
        onCrop({
          crop: this.state.crop,
          img: this.state.picturePreviewSize,
          isCrop: confirm,
        });
      closeModal();
    }
  };

  render() {
    const { nodeId, closeModal, hideSidebar, download, allowOverflow } =
      this.props;
    const {
      uses,
      use,
      crop,
      size,
      finalSize,
      pictureOriginalSize,
      scale,
      availableConversions,
      picturePreviewSize,
      isDownloading,
      previewTimestamp,
      previewLoading,
    } = this.state;

    return (
      <ErrorBoundary>
        {/* Used to detect when the image preview is available */}
        <img
          src={fileContentApi.getLink({
            id: nodeId,
            type: 'preview',
            params: {
              ignoreIcon: true,
              time: previewTimestamp,
            },
          })}
          onLoad={() => this.setState({ previewLoading: false })}
          onError={() =>
            // retry until the image is available
            setTimeout(
              () => this.setState({ previewTimestamp: new Date().getTime() }),
              1000
            )
          }
          style={{ display: 'none' }}
        />
        <StyledWrapper>
          <ModalBar>
            <Typography variant="h3">
              <FormattedMessage id="file.cropViewTitle" />
            </Typography>
            <ModalCloseButton closeModal={() => closeModal()} />
          </ModalBar>
          <StyledContent allowOverflow={allowOverflow}>
            {use && (
              <React.Fragment>
                <Picture
                  nodeId={nodeId}
                  crop={crop}
                  size={size}
                  originalSize={pictureOriginalSize}
                  onSetCrop={this.onSetCrop}
                  onAdjustCropDimension={this.onAdjustCropDimension}
                  use={use}
                  allowOverflow={allowOverflow}
                  previewLoading={previewLoading}
                />
                {!hideSidebar && (
                  <SideBar
                    uses={uses}
                    use={use}
                    size={size}
                    scale={scale}
                    finalSize={finalSize}
                    previewSize={picturePreviewSize}
                    maximumCropSize={pictureOriginalSize}
                    availableConversions={availableConversions}
                    isDownloading={isDownloading}
                    onAdjustCropDimension={this.onAdjustCropDimension}
                    onAdjustFinalSize={this.onAdjustFinalSize}
                    onSelectUse={this.onSelectUse}
                    onDownload={this.onDownload}
                    onToggleFinalSizeRatioLock={this.onToggleFinalSizeRatioLock}
                  />
                )}
              </React.Fragment>
            )}
            {!use && (
              <UseSelection
                nodeId={nodeId}
                uses={uses}
                availableConversions={availableConversions}
                onSelectUse={this.onSelectUse}
              />
            )}
          </StyledContent>
          <StyledModalButtonBar>
            {use && (
              <>
                {!download && (
                  <Button
                    variant="outlined"
                    onClick={() => this.handleConfirmClick(false)}
                  >
                    <FormattedMessage id="file.removeCrop" />
                  </Button>
                )}

                {!this.props.hideAddMaterialButton && (
                  <LoadableButton
                    onClick={async () => {
                      this.setState({ isProcessing: true });
                      await this.props.onAddMaterial(this.getFileData());
                      this.setState({ isProcessing: false });
                    }}
                    color="primary"
                    variant="contained"
                    disabled={this.state.isProcessing}
                    loading={this.state.isProcessing}
                  >
                    <Trans>Save as new material</Trans>
                  </LoadableButton>
                )}

                {!this.props.hideDownload && (
                  <ConfirmButton
                    color="primary"
                    variant="contained"
                    onClick={this.handleConfirmClick}
                    isDownloading={isDownloading}
                    disabled={isDownloading}
                  >
                    <FormattedMessage
                      id={
                        download
                          ? isDownloading
                            ? 'isDownloading'
                            : 'download'
                          : 'crop'
                      }
                    />
                    {isDownloading && <LoadingIcon />}
                  </ConfirmButton>
                )}
              </>
            )}
          </StyledModalButtonBar>
        </StyledWrapper>
      </ErrorBoundary>
    );
  }
}

const StyledWrapper = styled(ModalWrapper)`
  && {
    @media screen and (min-width: 960px) {
      min-width: '900px';
    }
  }
`;

const StyledContent = styled(ModalContent)`
  && {
    display: flex;
    flex-direction: row;

    @media screen and (max-width: 900px) {
      flex-direction: column;
      align-items: center;
    }
    @media screen and (min-width: 600px) {
      max-height: calc(100vh - 144px);
    }
    @media screen and (min-width: 900px) {
      justify-content: center;

      & > *:not(:last-child) {
        margin-right: 16px;
      }
    }
  }
`;

const StyledModalButtonBar = styled(ModalButtonBar)`
  @media screen and (max-width: 600px) {
    flex-wrap: wrap;
    height: auto;

    & > button {
      margin-right: 0;
    }
  }
`;

const ConfirmButton = styled(Button)`
  && {
    ${props =>
      props.isDownloading &&
      `
      color: #cdcdcd;
      cursor: not-allowed;
      border-color: #cdcdcd;
    `}

    &:hover {
      ${props =>
        !props.isDownloading &&
        `
        background-color: #186d8a;
        color: #fff;`}
    }
  }
`;

/* eslint-disable max-len */
const LoadingIcon = styled.div`
  background: url('../js/ext-3.0.0/resources/images/default/grid/grid-loading.gif')
    no-repeat scroll right top;
  background-size: cover;
  margin-left: 5px;
  width: 16px;
  height: 16px;
`;

export default CropContainer;
