import React, {
  ComponentProps,
  ComponentType,
  PropsWithChildren,
  useState,
} from 'react';

import Button from './Button';

type Props<P> = PropsWithChildren<
  P & {
    loading?: boolean;
    autodisable?: boolean;
    autoload?: boolean;
    onClick?: () => any;
    ButtonComponent?: ComponentType<P>;
  }
>;

const LoadableButton = React.forwardRef(
  (
    {
      autoload,
      autodisable,
      onClick,
      loading,
      ButtonComponent = Button as any,
      ...props
    }: Props<ComponentProps<typeof ButtonComponent>>,
    ref
  ) => {
    const [autoloading, setAutoloading] = useState(false);

    const handleClick = async () => {
      setAutoloading(true);
      onClick && (await onClick());
      setAutoloading(false);
    };

    const disabled = props.disabled || (autodisable && autoloading);

    return (
      <Button
        loading={loading || (autoload && autoloading)}
        disabled={disabled}
        onClick={handleClick}
        as={ButtonComponent as any}
        ref={ref}
        {...props}
      >
        {props.children}
      </Button>
    );
  }
);
LoadableButton.displayName = 'LoadableButton';

export default LoadableButton;
