import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useDrop } from 'react-dnd';
import CloudUpload from '@mui/icons-material/CloudUpload';

import { useSelector } from 'react-redux';
import { useAddFiles } from '../files/common/add.utils';
import { useAddToWorkspace } from './hooks';

import { dndTypes } from '~common/misc/drag/constants';
import { UploadFile } from '~common/content.types';
import { blobToUploadFile } from '~common/misc/drag/utils';

type DropItem = {
  type: typeof dndTypes['NATIVE_FILE'];
  files: File[];
};

interface Props {
  workspaceId: string;
  files: UploadFile[];
  setFiles: React.Dispatch<React.SetStateAction<UploadFile[]>>;
}

export function UploadDropzone({ workspaceId, files, setFiles }: Props) {
  const user = useSelector(state => state.app.user);

  const { addToWorkspace } = useAddToWorkspace();

  const { startUpload, uploadDone, uploading, uploadedIds, reset } =
    useAddFiles({
      targetFolderId: user?.homeFolderId ?? '',
      files,
      setFiles,
      metaFields: [],
      metaData: {},
    });

  // Start uploading right away after some files have been dropped
  useEffect(() => {
    if (files.length > 0 && !uploading) startUpload();
  }, [files.map(file => file.name).join('-')]);

  // After uploading has been completed, add all newly uploaded files to workspace
  useEffect(() => {
    if (uploadDone && uploadedIds.length === files.length) {
      addToWorkspace({ workspaceId, itemIds: uploadedIds });
      reset();
    }
  }, [uploadDone, uploadedIds.join('-')]);

  const [{ isOver, canDrop, isDragging }, drop] = useDrop({
    accept: dndTypes.NATIVE_FILE,
    drop: (item: DropItem) => {
      setFiles(files => [
        ...files,
        ...item.files.map(file => blobToUploadFile(file, { timestamp: true })),
      ]);
    },
    collect: monitor => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
      isDragging: monitor.getItem() && Array.isArray(monitor.getItem().files),
    }),
  });

  return (
    <Zone ref={drop} show={isDragging} canDrop={canDrop} hover={isOver}>
      <UploadIcon />
    </Zone>
  );
}

interface ZoneProps {
  show: boolean;
  hover: boolean;
  canDrop: boolean;
}

const Zone = styled.div<ZoneProps>`
  --right-sidebar-width: 282px;
  --navbar-height: 56px;
  --button-bar-height: 73px;
  --bottom-bar-height: 57px;
  --margin: ${p => p.theme.spacing(1)};

  display: ${p => (p.show ? 'flex' : 'none')};
  justify-content: center;
  align-items: center;

  position: fixed;
  min-width: unset;
  width: calc(var(--right-sidebar-width) - var(--margin) * 2);
  top: calc(
    var(--navbar-height) + var(--button-bar-height) * 2 + var(--margin)
  );
  bottom: calc(var(--bottom-bar-height) + var(--margin));
  right: var(--margin);

  background-color: ${p =>
    p.hover
      ? 'rgba(20, 110, 255, 0.7)'
      : p.canDrop
      ? 'rgba(20, 110, 255, 0.5)'
      : 'rgba(20, 110, 255, 0.2)'};
  border-radius: 5px;
  border: 3px blue solid;

  z-index: 999999999;
`;

const UploadIcon = styled(CloudUpload)`
  font-size: 72pt !important;
`;
