import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { CircularProgress } from '@mui/material';

import { Cropper } from './Cropper';
import fileContentApi from './fileContent.api';
import cropperStyles from './cropperStyles.jsx';
import { BREAKPOINTS } from '~common/utils/styled.utils';

// eslint-disable-next-line no-unused-vars
class Picture extends Component {
  static propTypes = {
    use: PropTypes.object,
    crop: PropTypes.any.isRequired,
    size: PropTypes.object.isRequired,
    originalSize: PropTypes.object.isRequired,
    onSetCrop: PropTypes.func.isRequired,
    onAdjustCropDimension: PropTypes.func.isRequired,
    nodeId: PropTypes.string,
    allowOverflow: PropTypes.bool,
    previewLoading: PropTypes.bool,
  };

  render() {
    const {
      nodeId,
      crop,
      size,
      originalSize,
      use,
      onSetCrop,
      allowOverflow,
      previewLoading,
    } = this.props;
    const fixedRatio = !!(use && use.proportions && use.proportions.x);
    const ratio = fixedRatio ? use.proportions.x / use.proportions.y : null;
    let optionalProps = fixedRatio ? { fixedRatio, ratio } : { fixedRatio };

    if (size.x && size.y) {
      optionalProps.width = size.x ? parseFloat(size.x, 10) : null;
      optionalProps.height = size.y ? parseFloat(size.y, 10) : null;
    }

    if (crop.x1 || crop.y1) {
      optionalProps = {
        ...optionalProps,
        originX: parseFloat(crop.x1, 10),
        originY: parseFloat(crop.y1, 10),
      };
    }

    if (previewLoading)
      return (
        <PictureWrapper>
          <CircularProgress color="primary" />
        </PictureWrapper>
      );

    // Set ratio as the key for Cropper to force a re-render when changing use
    return (
      <PictureWrapper allowOverflow={allowOverflow}>
        <span>
          <FormattedMessage id="file.cropOriginalImageSize" /> {originalSize.x}{' '}
          × {originalSize.y} <FormattedMessage id="file.cropPixels" />.
        </span>
        <CropperContainer>
          <Cropper
            key={ratio}
            alt="preview"
            src={fileContentApi.getLink({ id: nodeId, type: 'preview' })}
            onChange={onSetCrop}
            styles={cropperStyles}
            allowNewSelection={false}
            allowOverflow={allowOverflow}
            ref={ref => {
              this.cropper = ref;
            }}
            {...optionalProps}
          />
        </CropperContainer>
      </PictureWrapper>
    );
  }
}

const PictureWrapper = styled.div`
  flex: 0 1 auto; /* The Cropper component doesn't behave well if grown */
  display: inline-block;
  padding: 16px 0; /* The dots can go outside of bounds without this */
  padding-bottom: 10px;
  max-height: inherit;
  box-sizing: content-box;
  max-width: 100%;

  ${props => props.allowOverflow && 'padding: 50px; padding-top: 40px;'}

  &:only-child {
    margin: auto;
  }

  @media screen and (max-width: ${BREAKPOINTS.sm}px) {
    padding: 8px;
  }
`;

const CropperContainer = styled.div`
  padding-top: 10px;
`;

export default Picture;
