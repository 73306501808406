import React from 'react';
import { connect, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { t } from '@lingui/macro';
import { IntlProvider } from 'react-intl';
import { mapKeys } from 'lodash';

import Axios from 'axios';
import Crop from './Crop';
import commonTranslations from '../../common/messages';
import fileTranslations from './translations';
import fileContentApi from './fileContent.api';
import { app } from '~common/app.model';
import { commonContent } from '~common/content.model';
import { getFileExtension } from '~common/content.utils';

// TODO: typing
interface Props {
  customerId: string;
  file: any;
  userRights: any;
  closeModal: any;
  readFile: any;
  mode?: 'download' | 'crop' | 'addMaterial';
  use?: any;
  onCrop?: (crop: any) => any;
  allowOverflow?: boolean;
  userLang: string;
  setOpenSnackbar: (id: string, options: any) => void;
  existingFiles?: any[];
}

interface FileData {
  crop: { x1: number; x2: number; y1: number; y2: number };
  id: string;
  size: {
    x: number;
    y: number;
  };
  type: string;
}

const CropWrapper = ({
  customerId,
  file,
  existingFiles,
  userRights,
  closeModal,
  readFile,
  mode = 'download',
  userLang,
  setOpenSnackbar,
  ...props
}: Props) => {
  const cropFileNameModelId = useSelector(
    state => state.app.customer?.downloadConfig?.cropFileNameModelId
  );
  const lang = userLang;
  const messages = {
    ...commonTranslations[lang](),
    ...mapKeys(fileTranslations[lang](), (value, key: string) => `file.${key}`),
  };

  const history = useHistory();

  const getFileName = (file: any, data: FileData) => {
    const orgName = file.name.split('.').slice(0, -1).join('.');
    return `${orgName}_${data.size.x}x${data.size.y}`;
  };

  const onSuccess = () =>
    setOpenSnackbar('WORKSPACE/CROP_SUCCESS', {
      type: 'success',
      message: t`Cropped item created`,
    });

  const onAddMaterial = async (data: FileData) => {
    const req = await Axios.get(
      fileContentApi.getLink({
        ...data,
        isDownload: true,
        maskid: undefined,
      }),
      {
        responseType: 'arraybuffer',
        withCredentials: true,
      }
    );
    const blob = new Blob([req.data]);
    const mimetype = (
      req.headers['content-type'] ?? file?.propertiesById['nibo:mime-type']
    )?.split('/');
    const name = getFileName(file, data);
    const ext = getFileExtension(file);

    const existing = existingFiles?.filter(f => f.orgName === name) ?? [];
    const lastNameOffset = existing.reduce(
      (max, cur) => (cur.nameOffset > max ? cur.nameOffset : max),
      -1
    );
    const nameOffset = lastNameOffset + 1;

    const newFiles = [
      ...(existingFiles ?? []),
      {
        blob,
        preview: URL.createObjectURL(blob),
        name:
          nameOffset === 0 ? `${name}.${ext}` : `${name}_${nameOffset}.${ext}`,
        orgName: name,
        nameOffset,
        type: mimetype[0],
        subtype: mimetype[1],
        metaById: file.metaById,
      },
    ];

    const targetPath = `/folders/${file.node.parentId ?? file.parentId}/add`;
    // This is done like this to prevent history.goBack() from scrolling through the files
    // in the add files -view
    const method =
      history.location.pathname === targetPath ? history.replace : history.push;
    method(targetPath, {
      files: newFiles,
      croppedFile: file,
    });

    onSuccess();
    closeModal();
  };

  return (
    <IntlProvider locale={lang} messages={messages}>
      <Crop
        closeModal={closeModal}
        nodeId={file.node.id}
        customerId={customerId}
        hideSidebar={mode === 'crop'}
        download={mode === 'download' || mode === 'addMaterial'}
        onAddMaterial={onAddMaterial}
        onDownload={() => onSuccess()}
        hideAddMaterialButton={
          !userRights ||
          !userRights.MATERIAL_MANAGE ||
          !userRights.MATERIAL_MULTIPLE_FILE_UPLOAD ||
          mode === 'crop'
        }
        hideDownload={mode === 'addMaterial'}
        nameConfigId={cropFileNameModelId}
        {...props}
      />
    </IntlProvider>
  );
};

const ConnectedCropWrapper = connect(
  (state: any) => ({
    customerId: state.app.customer.id,
    userLang: state.app.settings.language,
    userRights: state.app.userRights,
  }),
  {
    closeModal: app.actions.closeModal,
    readFile: commonContent.actions.readFile,
    setOpenSnackbar: app.actions.setOpenSnackbar,
  }
)(CropWrapper);

export const ProductCropWrapper = ({ file, proportions, onCrop }) => {
  return (
    <ConnectedCropWrapper
      mode="crop"
      file={file}
      use={{ proportions, contentType: 'original' }}
      onCrop={onCrop}
      allowOverflow
    />
  );
};

export default ConnectedCropWrapper;
