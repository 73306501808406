import config from './config';
import { convertFileIn } from './content.utils';
import { apiBase } from './api.base';
import { Product } from './product.types';
import api, { toAbsoluteUrl } from '~utils/api.utils';
import { getFileTag, openLink } from '~common/content.api';
export {
  downloadPdf as downloadProductPdf,
  getDownloadPdfLink as getDownloadProductPdfLink,
} from '~common/content.api';

export const createProduct = async ({ templateId }): Promise<any> => {
  const response = (await api.http.post(
    `/products/`,
    { masterProductId: templateId } // TODO: rename masterProductId->templateId
  )) as any;
  return response.data;
};

export const readPages = async ({ productId }): Promise<any> => {
  const productPages = (await api.http.get(`products/${productId}/pages`)) as {
    data: object;
  };
  Object.values(productPages.data).forEach(page =>
    Object.assign(page, {
      thumbnailUrl: toAbsoluteUrl(page.thumbnailUrl),
    })
  );
  return productPages.data;
};

export const readPageTemplates = async ({ templateId }): Promise<any> => {
  const productPageTemplates = (await api.http.get(
    `/templates/${templateId}/pages`
  )) as {
    data: object;
  };
  Object.values(productPageTemplates.data).forEach(template =>
    Object.assign(template, {
      thumbnailUrl: toAbsoluteUrl(template.thumbnailUrl),
    })
  );
  return productPageTemplates.data;
};

export const readElements = async ({ productId }): Promise<any> => {
  const elements = await readElementsOnPage({ productId, pageId: 1 });
  return elements;
};

export const readAvailableOptions = async ({ elementId }) => {
  const files = await api.http.get(`/elements/${elementId}/options`);
  const data = {} as Record<string, ReturnType<typeof convertFileIn>>;
  Object.keys(files.data).forEach(id => {
    if (files.data[id]) {
      data[id] = convertFileIn(files.data[id]);
    }
  });
  return data;
};

export const readElementsOnPage = async ({
  productId,
  pageId,
}): Promise<any> => {
  const elements = await api.http.get(
    `/products/${productId}/pages/${pageId}/elements`
  );
  return elements.data;
};

export const getDownloadProductImgLink = (
  userProductId: string,
  type: 'animatedImg' | 'userImg',
  selectedPageId: number
) =>
  `${config.apiUrl}/products/${userProductId}/download/${type}/pages/${selectedPageId}`;
export const downloadProductImg = async (
  userProductId: string,
  type: 'animatedImg' | 'userImg',
  selectedPageId: number
) => {
  const link = getDownloadProductImgLink(userProductId, type, selectedPageId);
  openLink(link);
};

export const isProductDownloadEnabled = async ({ productId, type }) => {
  const res = await api.http.get(
    `products/${productId}/download/${type}/enabled`
  );
  return res.data;
};

export const updateElement = async ({ elementId, element }): Promise<any> => {
  return api.http.put(`/elements/${elementId}`, { ...element });
};

export const updateProductMetaLang = async ({
  productId,
  product,
}): Promise<any> => {
  return api.http.put(`/products/${productId}/meta_lang`, {
    metaLang: product.metaLanguage,
  });
};

export const addPage = async ({ productId, templatePageId }): Promise<any> => {
  const productPageTemplates = (await api.http.post(
    `/products/${productId}/pages`,
    { templatePageId: templatePageId }
  )) as any;
  return productPageTemplates.data;
};

export const deletePage = async ({ productId, pageId }): Promise<any> => {
  const productPageTemplates = (await api.http.delete(
    `/products/${productId}/pages/${pageId}`
  )) as any;
  return productPageTemplates.data;
};

export const updatePageOrder = async ({
  productId,
  indexFrom,
  indexTo,
}): Promise<any> => {
  const productPageTemplates = (await api.http.put(
    `/products/${productId}/pages/changeOrder`,
    {
      from: indexFrom,
      to: indexTo,
    }
  )) as any;
  return productPageTemplates.data;
};
// TODO get rid of this
export const copyProdTemplate = async ({ productId, name, temp = false }) => {
  const productPageTemplates = await api.http.post(
    `/products/${productId}/copy?temp=${temp}`,
    { name: name }
  );
  return productPageTemplates;
};

export const finishEditingProduct = async ({ productId }) => {
  await api.http.get(
    `/products/${productId}/pages/1/previews/FULL?saveAttachmentsOnly=true&counter=${new Date().getTime()}`
  );
};

interface RenameProductArgs {
  productId: string;
  nodeId: string;
  name: string;
}

interface UpdateProductArgs {
  productId: string;
  name?: string;
  scaleX?: number;
  scaleY?: number;
  metaLang?: string;
}

interface CopyProductArgs {
  productId: string;
  name: string;
  temp?: boolean;
}

export type MassImportRow = {
  id: string;
  rowValues: string[];
};

type MassImportQueryResult = {
  massImports: MassImportRow[];
  columnCount: number;
};

interface UpdateMassImportArgs {
  productId: string;
  massImports: MassImportRow[];
}

type UpdateMassImportMultipartParams = {
  productId: string;
  file: any;
  charset: string;
  separator: string;
};

export type ImportCharset = 'UTF-8' | 'ISO-8859-15' | 'MacRoman';

const getProductTag = (id: string) => ({ type: 'Product' as const, id });
const getMassImportTag = (id: string) => ({ type: 'MassImport' as const, id });

const extendedApi = apiBase.injectEndpoints({
  endpoints: builder => ({
    getProduct: builder.query<Product, { productId: string }>({
      query: ({ productId }) => ({
        url: `/products/${productId}`,
        method: 'get',
      }),
      transformResponse: (data: any, _, { productId }) => ({
        ...data,
        wysiwygSettings: {
          superscriptPosition: 0.33,
          superscriptHeight: 0.583,
          subscriptPosition: 0.33,
          subscriptHeight: 0.583,
          ...data.wysiwygSettings,
        },
        productId,
      }),
      providesTags: (_, __, { productId }) => [getProductTag(productId)],
    }),

    updateProduct: builder.mutation<void, UpdateProductArgs>({
      query: ({ productId, name, scaleX, scaleY }) => ({
        url: `/products/${productId}`,
        method: 'put',
        data: { name, scaleX, scaleY },
      }),
      invalidatesTags: (_, __, { productId }) => [getProductTag(productId)],
    }),

    renameProduct: builder.mutation<void, RenameProductArgs>({
      query: ({ productId, nodeId, name }) => ({
        url: `/products/${productId}/rename`,
        method: 'put',
        data: { nodeId, name },
      }),
      invalidatesTags: (_, __, { productId, nodeId }) => [
        getProductTag(productId),
        getFileTag(nodeId),
      ],
    }),

    copyProdTemplate: builder.mutation<{ id: string }, CopyProductArgs>({
      query: ({ productId, name, temp = false }) => ({
        url: `/products/${productId}/copy?temp=${temp}`,
        method: 'post',
        data: { name },
      }),
      invalidatesTags: (_, __, { productId }) => [getProductTag(productId)],
    }),

    getMassImport: builder.query<MassImportQueryResult, { productId: string }>({
      query: ({ productId }) => ({
        url: `/massImport/${productId}`,
        method: 'get',
      }),
      transformResponse: (data: MassImportQueryResult, _, { productId }) => ({
        ...data,
        productId,
      }),
      providesTags: (_, __, { productId }) => [getMassImportTag(productId)],
    }),

    updateMassImport: builder.mutation<void, UpdateMassImportArgs>({
      query: ({ productId, massImports }) => ({
        url: `/massImport/${productId}`,
        method: 'put',
        data: { massImports },
      }),
      invalidatesTags: (_, __, { productId }) => [getMassImportTag(productId)],
    }),

    updateMassImportFile: builder.mutation<
      void,
      UpdateMassImportMultipartParams
    >({
      query: ({ productId, file, charset, separator }) => {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('charset', charset);
        formData.append('separator', separator);

        return {
          url: `${config.API_URL}/massImport/${productId}`,
          method: 'post',
          data: formData,
          headers: {
            'Content-Type': `multipart/form-data; boundary=${
              (formData as any)._boundary
            }`,
          },
        };
      },
      invalidatesTags: (_, __, { productId }) => [getMassImportTag(productId)],
    }),
  }),
});

const {
  util: { invalidateTags },
  endpoints: { getMassImport },
} = extendedApi;

export const invalidateMassImport = () => invalidateTags(['MassImport']);
export const {
  useGetProductQuery,
  useUpdateProductMutation,
  useRenameProductMutation,
  useCopyProdTemplateMutation,
  useGetMassImportQuery,
  useUpdateMassImportMutation,
  useUpdateMassImportFileMutation,
} = extendedApi;
