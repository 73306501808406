import React, { Component } from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { has } from 'lodash';
import { t } from '@lingui/macro';

import { SideBarSectionCollapse } from './SideBarSection';
import Dimensions from './Dimensions';
import DropDown from '~common/inputs/DropDown';
import { getLangValue } from '~common/app.utils';

type Size = { x: number; y: number };

interface Props {
  uses: any[];
  use?: any;
  size: any;
  scale: number;
  finalSize: Size;
  maximumCropSize: Size;
  availableConversions: any[];
  onAdjustCropDimension: (size: Size) => void;
  onAdjustFinalSize: (size: Size) => void;
  onSelectUse: (...args: any[]) => void;
  onToggleFinalSizeRatioLock: () => void;
}

class SideBar extends Component<Props> {
  state = {
    collapses: {
      shape: true,
      finalSize: false,
    },
  };

  handleCollapse = id => {
    return () => {
      this.setState({
        collapses: {
          ...this.state.collapses,
          [id]: !this.state.collapses[id],
        },
      });
    };
  };

  render = () => {
    const {
      uses,
      use,
      size,
      finalSize,
      maximumCropSize,
      onAdjustCropDimension,
      onAdjustFinalSize,
      onSelectUse,
      scale,
      onToggleFinalSizeRatioLock,
      availableConversions,
    } = this.props;
    const forcedSize = !!(use.size && use.size.x && use.size.y);
    return (
      <SideBarWrapper>
        <SideBarSectionCollapse
          title={<FormattedMessage id="file.cropImageShape" />}
          open={this.state.collapses.shape}
          handleCollapse={this.handleCollapse('shape')}
        >
          <SectionWrapper>
            <SubSection>
              <DropDown
                id="crop-shape-selector"
                labelText={t`Crop shape`}
                onChange={onSelectUse}
                variant="outlined"
                value={use.id}
                options={uses
                  .filter(
                    use => availableConversions.indexOf(use.contentType) !== -1
                  )
                  .map(use => ({
                    value: use.id,
                    name: getLangValue(use.namesByLang),
                  }))}
                showEmpty={false}
              />
            </SubSection>
            <SubSection>
              <Dimensions
                x={size.x}
                y={size.y}
                maximumCropSize={maximumCropSize}
                onAdjust={onAdjustCropDimension}
                ratio={
                  use.proportions && use.proportions.x
                    ? use.proportions.x / use.proportions.y
                    : undefined
                }
                scale={scale}
              />
            </SubSection>
          </SectionWrapper>
        </SideBarSectionCollapse>
        <SideBarSectionCollapse
          title={<FormattedMessage id="file.cropFinalImageSize" />}
          open={this.state.collapses.finalSize}
          handleCollapse={this.handleCollapse('finalSize')}
        >
          <SubSection>
            <Dimensions
              x={finalSize.x}
              y={finalSize.y}
              onAdjust={onAdjustFinalSize}
              ratio={size.x / size.y}
              forcedSize={forcedSize}
              onToggleFinalSizeRatioLock={onToggleFinalSizeRatioLock}
              toggleableLock={!has(use, 'proportions.x') && !has(use, 'size.x')}
            />
          </SubSection>
        </SideBarSectionCollapse>
        {(Math.round(size.x * scale) < finalSize.x ||
          Math.round(size.y * scale) < finalSize.y) && (
          <WarningContainer>
            <FormattedMessage id="file.cropSizeWarning" />
          </WarningContainer>
        )}
      </SideBarWrapper>
    );
  };
}

const SideBarWrapper = styled.div`
  flex: 0;
  display: inline-block;
  vertical-align: top;
  /* border-left: 1px #ECECEC solid; */
  padding: 45px 0 16px 0;
  min-width: 300px;
  width: 300px;
  box-sizing: border-box;
  align-self: stretch;

  @media screen and (max-width: 900px) {
    width: auto;
  }
`;

const SubSection = styled.div`
  display: flex;
  flex-direction: column;
  padding: 15px 5px 15px 5px;
`;

const SectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const WarningContainer = styled.div`
  margin: 16px 5px;
  color: red;
`;

export default SideBar;
