import React, { Component } from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { t } from '@lingui/macro';

import Lock from '@mui/icons-material/Lock';
import LockOpen from '@mui/icons-material/LockOpen';
import Close from '@mui/icons-material/Close';
import { TextField } from '~inputs/TextField';

interface Props {
  x?: number;
  y?: number;
  onAdjust: (params: { x: number; y: number }) => void; // NOTE This function can be anything
  ratio?: number;
  scale: number;
  forcedSize?: boolean;
  maximumCropSize?: { x: number; y: number };
  onToggleFinalSizeRatioLock?: () => void;
  toggleableLock?: boolean;
}

interface State {
  x: number;
  y: number;
  lock: boolean;
}

class Dimensions extends Component<Props, State> {
  static defaultProps = {
    scale: 1.0,
  };

  constructor(props) {
    super(props);
    this.state = {
      x: Math.round(props.x * props.scale), // Temporary values when editing
      y: Math.round(props.y * props.scale),
      lock: !!props.ratio,
    };
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(props) {
    this.setState({
      x: Math.round(props.x * props.scale),
      y: Math.round(props.y * props.scale),
      lock: !!props.ratio,
    });
  }

  handleChange(event, axis) {
    const val = event.target.value;
    const { maximumCropSize, scale } = this.props;
    const ratio = this.state.lock ? this.props.ratio : undefined;
    let x;
    let y;
    if (axis === 'x') {
      x = maximumCropSize ? Math.min(val, maximumCropSize.x) : val;
      // Adjust y according to aspect ratio
      y = ratio ? Math.round(x / ratio) : this.state.y;
    } else {
      y = maximumCropSize ? Math.min(val, maximumCropSize.y) : val;
      x = ratio ? Math.round(y * ratio) : this.state.x;
    }
    this.setState({ x, y });
    this.props.onAdjust({
      x: x / scale,
      y: y / scale,
    });
  }

  toggleLock() {
    this.props.onToggleFinalSizeRatioLock?.();
    this.setState({
      lock: !this.state.lock,
    });
  }

  render() {
    return (
      <DimensionsWrapper>
        <Dimension>
          <DimensionInput
            id="dimension-input-field-x"
            label={t`Width`}
            disabled={this.props.forcedSize}
            value={this.state.x || ''}
            type="number"
            onChange={event => this.handleChange(event, 'x')}
          />
        </Dimension>
        <Multiplier>
          {this.state.lock ? (
            <LockIcon
              onClick={
                this.props.toggleableLock ? () => this.toggleLock() : undefined
              }
            />
          ) : (
            <LockOpenIcon
              onClick={
                this.props.toggleableLock ? () => this.toggleLock() : undefined
              }
            />
          )}
          <CrossIcon />
        </Multiplier>
        <Dimension>
          <DimensionInput
            id="dimension-input-field-y"
            label={t`Height`}
            disabled={this.props.forcedSize}
            value={this.state.y || ''}
            type="number"
            onChange={event => this.handleChange(event, 'y')}
          />
        </Dimension>
        <Pixels>
          <FormattedMessage id="file.cropPixels" />
        </Pixels>
      </DimensionsWrapper>
    );
  }
}

const DimensionsWrapper = styled.div`
  display: flex;
  align-items: flex-end;
`;

const Dimension = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 8px;
`;

const DimensionInput = styled(TextField)`
  max-width: 80px;
`;

const Multiplier = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-right: 8px;
  padding-bottom: 3px;
  padding-top: 1px;
  height: fit-content;
  gap: ${p => p.theme.spacing(1)};
`;

const iconSize = '14px';
const LockIcon = styled(Lock)`
  && {
    // cursor: pointer;
    font-size: ${iconSize};
  }
`;

const LockOpenIcon = styled(LockOpen)`
  && {
    // cursor: pointer;
    font-size: ${iconSize};
  }
`;

const CrossIcon = styled(Close)`
  && {
    font-size: ${iconSize};
  }
`;

const Pixels = styled.span`
  margin-top: auto;
`;

export default Dimensions;
