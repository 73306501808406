/*
 * File content API endpoint: /v1/files/{id}/contents/{type}
 * See FileContentResource.java
 */
import qs from 'qs';
import api from '../../../common/utils/api.utils';
import config from '~common/config';

async function read({ id, type, size, crop }) {
  // Emulate API communication by returning hardcoded data with a small delay
  const data = `TODO ${id}, ${type}, ${size}, ${crop}`;
  return new Promise(resolve => setTimeout(() => resolve(data), 200));
}

async function update({ id, type }) {
  // Emulate API communication by returning hardcoded data with a small delay
  const data = `TODO ${id}, ${type}`;
  return new Promise(resolve => setTimeout(() => resolve(data), 200));
}

// Additional helper methods

function getLink({ id, type, isDownload, size, crop, maskid, params = {} }) {
  const url = `${config.apiUrl}/files/${id}/contents/${type}`;
  const queryParams = { ...params };
  if (isDownload) {
    queryParams.download = isDownload;
  }
  if (size && size.x && size.y) {
    queryParams.sizex = Math.round(size.x);
    queryParams.sizey = Math.round(size.y);
  }
  if (crop && (crop.x1 || crop.x2 || crop.y1 || crop.y2)) {
    queryParams.x1 = Math.round(crop.x1);
    queryParams.y1 = Math.round(crop.y1);
    queryParams.x2 = Math.round(crop.x2);
    queryParams.y2 = Math.round(crop.y2);
  }
  if (maskid) {
    queryParams.maskid = maskid;
  }
  return `${url}?${qs.stringify(queryParams)}`;
}

async function download({ id, type, size, crop, maskid, params = {} }) {
  const link = getLink({
    id,
    type,
    isDownload: true,
    size,
    crop,
    maskid,
    params,
  });
  const iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
  if (iOS) {
    window.open(link, '_blank');
  } else {
    window.location = `${link}`;
  }
}

async function fetchDimensions({ id }) {
  const res = await api.http.get(
    `${config.apiUrl}/files/${id}?include=image,attachments&isCropView=true`
  );
  return res.data;
}

async function getImageInfo({ id }) {
  const res = await api.http.get(`${config.apiUrl}/files/${id}/imageinfo`);
  return res.data;
}

async function isImageInfoExists({ id }) {
  try {
    const data = await this.getImageInfo({ id });
    return data?.exists === true;
  } catch (err) {
    return false;
  }
}

export default {
  read,
  update,
  getLink,
  download,
  fetchDimensions,
  getImageInfo,
  isImageInfoExists,
};
