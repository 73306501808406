import React, { useEffect, useState, useRef, CSSProperties } from 'react';
import { useSelector } from 'react-redux';
import { Trans, t } from '@lingui/macro';
import styled from 'styled-components';

import FileBrowseSearch from './FileBrowseSearch';
import FileSelector from './FileSelector';
import FileUploader from './FileUploader';

import Tab from '~navigation/Tab';
import Tabs from '~navigation/Tabs';
import { AddIcon, ImageIcon, SearchIcon, TextFieldsIcon } from '~misc/icons';
import TabContent from '~sections/TabContent';
import PageContent from '~sections/PageContent';
import Button from '~common/inputs/Button';
import ButtonBar from '~common/sections/ButtonBar';

import { commonContent } from '~common/content.model';
import { getFileName } from '~common/content.utils';
import TabPanel from '~common/navigation/TabPanel';
import { useActions, usePrevious } from '~common/utils/hooks.utils';
import { File, UploadFile } from '~common/content.types';
import { filterUndefined } from '~common/utils/fn.utils';

type Mode = {
  label: string;
  id?: string;
  icon: JSX.Element;
  content: React.ReactElement;
};

interface Props {
  contentType?: 'file' | 'text';
  selectedFileId?: string;
  onSelectNull?: () => void;
  customModes?: Mode[];
  order?: string;
  // Select file parameters
  fileIds: Array<string>;
  readFilesImpl?: (fileIds: string[]) => void;
  onSelectFile?: (file: File, index: number) => void;
  filterBy?: (file: any) => boolean;
  // Browse file parameters
  rootFolderId?: string;
  initialFolderId?: string;
  onSelectBrowsedFile?: (file: any) => void;
  // Upload file parameters
  onUploadFile?: (file: any) => void;
  uploading?: boolean;
  onCancelUpload?: () => void;
  contentStyle?: CSSProperties;
  horizontal?: boolean;
  beforeUpload?: (file: UploadFile, handleUpload: () => void) => void;
  selectedElementId?: string | null | undefined;
}

const FilePicker = ({
  contentType = 'file',
  selectedFileId,
  onSelectNull,
  customModes,
  order,
  // Select file parameters
  fileIds,
  readFilesImpl,
  onSelectFile,
  filterBy = () => true,
  // Browse file parameters
  rootFolderId,
  initialFolderId,
  onSelectBrowsedFile,
  // Upload file parameters
  onUploadFile,
  uploading,
  onCancelUpload,
  contentStyle,
  horizontal,
  beforeUpload,
  selectedElementId,
}: Props) => {
  const filesById = useSelector(state => state.commonContent.filesById);
  const contentById = useSelector(state => state.commonContent.contentById);
  const language = useSelector(state => state.app.settings?.language) ?? '';
  const defaultLanguage =
    useSelector(state => state.app.customer?.defaultLanguage) ?? '';

  const { readFile, readFiles } = useActions(commonContent.actions);

  const [mode, setMode] = useState(0);
  const modes: Mode[] = [];
  const scrollRef = useRef<HTMLDivElement | null>(null);

  // Read files from server if file ids have changed
  useEffect(() => {
    readFilesImpl ? readFilesImpl(fileIds) : readFiles(fileIds, true);
  }, [fileIds.join('-')]);

  // Read file from server if selected file has changed
  useEffect(() => {
    if (selectedFileId) {
      readFile(selectedFileId);
    }
  }, [selectedFileId]);

  const scrollToTop = () => {
    const element = scrollRef && scrollRef.current;
    if (element) {
      // NOTE: old Edge does not support scrollTo for element.
      // Remove this try-catch fix later.
      try {
        element.scrollTo(0, 0);
      } catch (err) {
        element.scrollTop = 0;
      }
    }
  };

  // ------------------------------------------
  // Mode: Select file
  // ------------------------------------------

  // Handle current folder
  const [selectorFolderId, setSelectorFolderId] = useState<string | null>(null);

  const files = fileIds
    .map(id => filesById[id]?.file)
    .filter(filterUndefined)
    .filter(filterBy)
    .map(file =>
      file?.mimeGroup !== 'text'
        ? file
        : {
            ...file,
            textContent:
              contentById[file.node.id] && contentById[file.node.id].content,
          }
    )
    .sort((a, b) => {
      if (a.isFolder && !b.isFolder) {
        return -1;
      } else {
        return getFileName(a, language, defaultLanguage).localeCompare(
          getFileName(b, language, defaultLanguage)
        );
      }
    });

  if (onSelectFile && fileIds && fileIds.length > 0) {
    const onSelectFileOrFolder = (file: File, index: number) => {
      if (file.isFolder) {
        setSelectorFolderId(file.node.id);
        scrollToTop();
      } else {
        onSelectFile(file, index);
      }
    };

    let autoselectFolderId: string | null = null;
    if (files.length === 1 && files[0].isFolder) {
      autoselectFolderId = files[0].node.id;
    }

    const onBrowseOutside = autoselectFolderId
      ? undefined
      : () => {
          setSelectorFolderId(null);
        };

    const useFolderId = autoselectFolderId || selectorFolderId;

    modes.push({
      label: t`Select`,
      id: 'select',
      icon: contentType === 'text' ? <TextFieldsIcon /> : <ImageIcon />,
      content: (
        <>
          {!useFolderId && (
            <FileSelector
              files={files}
              fileCount={files.length}
              selectedFileId={selectedFileId}
              onSelectFile={onSelectFileOrFolder}
              showFilter={true}
              centered={true}
              thumbnailType="SELECTOR"
              language={language}
              defaultLanguage={defaultLanguage}
              horizontal={horizontal}
              contentDescription={t`Selectable content`}
            />
          )}
          {useFolderId && (
            <FileBrowseSearch
              mode="browse"
              contentType={contentType}
              rootFolderId={useFolderId}
              initialFolderId={useFolderId}
              selectedFileId={selectedFileId}
              filterBy={filterBy}
              onSelectBrowsedFile={onSelectFile}
              onSelectFolder={scrollToTop}
              onBrowseOutside={onBrowseOutside}
              horizontal={horizontal}
            />
          )}
        </>
      ),
    });
  }

  // ------------------------------------------
  // Mode: Browse files
  // ------------------------------------------

  if (onSelectBrowsedFile && rootFolderId && initialFolderId) {
    modes.push({
      label: t`Search`,
      id: 'search',
      icon: <SearchIcon />,
      content: (
        <FileBrowseSearch
          mode="search"
          contentType={contentType}
          rootFolderId={rootFolderId}
          initialFolderId={initialFolderId}
          selectedFileId={selectedFileId}
          filterBy={filterBy}
          onSelectBrowsedFile={onSelectBrowsedFile}
          onSelectFolder={scrollToTop}
          horizontal={horizontal}
          selectedElementId={selectedElementId}
        />
      ),
    });
  }

  // ------------------------------------------
  // Mode: Upload file
  // ------------------------------------------

  if (onUploadFile) {
    modes.push({
      label: t`Add`,
      id: 'add',
      icon: <AddIcon />,
      content: (
        <FileUploader
          onCancel={onCancelUpload}
          uploading={uploading}
          onUploadFile={onUploadFile}
          beforeUpload={beforeUpload}
        />
      ),
    });
  }

  if (customModes) {
    for (const customMode of customModes) modes.push(customMode);
  }

  if (order) {
    // modes without id (i.e. custom modes) or one not defined in order are to be placed last
    modes.sort(
      (a, b) =>
        ((a.id && order.indexOf(a.id) + 1) || Infinity) -
        ((b.id && order.indexOf(b.id) + 1) || Infinity)
    );
  }

  // ------------------------------------------
  // Render
  // ------------------------------------------

  useEffect(() => {
    if (selectorFolderId && !fileIds.includes(selectorFolderId)) {
      setSelectorFolderId(null);
    }
  }, [selectorFolderId, fileIds.join(' ')]);

  useEffect(() => {
    if (modes.length - 1 < mode) {
      setMode(0);
    }
  }, [modes.length]);

  return (
    <>
      {modes.length > 1 && (
        <Tabs
          aria-label={t`select file tabs`}
          indicatorColor="primary"
          withBorder
          variant="fullWidth"
          value={mode > modes.length - 1 ? modes.length - 1 : mode}
          onChange={(event: any, value: any) => setMode(value)}
        >
          {modes.map((mode, index) => (
            <Tab
              key={index}
              label={mode.label}
              icon={mode.icon}
              style={{ minWidth: '71px' }}
              id={`file-picker-tab-${index}`}
              aria-controls={`file-picker-tabpanel-${index}`}
            />
          ))}
        </Tabs>
      )}
      <TabContent top="104px" ref={scrollRef} style={contentStyle}>
        <>
          <PageContent padding={1} paddingHoriz={2}>
            {modes.map((tab, index) => (
              <TabPanel
                key={index}
                open={index === mode}
                id={`file-picker-tabpanel-${index}`}
                tabId={`file-picker-tab-${index}`}
              >
                {tab.content}
              </TabPanel>
            ))}
          </PageContent>
        </>
      </TabContent>
      {onSelectNull && (
        <StyledButtonBar>
          <Button variant="text" color="primary" onClick={onSelectNull}>
            {contentType === 'file' ? (
              <Trans>No image</Trans>
            ) : (
              <Trans>No text</Trans>
            )}
          </Button>
        </StyledButtonBar>
      )}
    </>
  );
};

const StyledButtonBar = styled(ButtonBar)`
  margin-top: 10px;
  button {
    width: 100% !important;
  }
`;

export default FilePicker;
