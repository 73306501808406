import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import mime from 'mime';
import { t } from '@lingui/macro';
import qs from 'qs';
import { merge } from 'lodash';
import { skipToken } from '@reduxjs/toolkit/query/react';

import config, { history } from './config';
import { conversionKeys } from './content.constants';

import {
  parseTags,
  encodeTags,
  DESCRIPTION_FIELD_ID,
  INSTRUCTION_FIELD_ID,
  useContentMode,
} from '../content/common/utils';
import { sha1 } from './utils/fn.utils';
import { Criteria, EnterMode, TranslatedData } from './common.types';
import {
  MetaFieldTree,
  MetaFieldTreeNode,
  TreeMetaField,
  File,
  Content,
  WorkspaceContent,
  WorkspaceShare,
  RemovedFile,
  Node,
  FileType,
  ConcreteNode,
  FileAttachment,
  MetaField,
  BaseFile,
  FilesById,
  RemovedItemsById,
  FileContainer,
  HandleFileConflict,
} from './content.types';
import {
  getShareKeyParameter,
  useGetCustomerMetaFieldsQuery,
} from './content.api';

import {
  getCustomerConfig,
  getLangValue,
  getUserFolderSort,
  getUserSearchSort,
} from './app.utils';
import { ALL_CUSTOMER_LANGUAGES, Lang } from '~utils/i18n';
import { useWindowSize } from '~common/utils/layout.utils';

export const getMetaFieldKey = (id: string, lang: string) =>
  `custom:meta-field-${id}_${lang}`;

export const parseMetaFieldKey = (
  key: `custom:meta-field-${number}` | `custom:meta-field-${number}_${Lang}`
) => {
  const match = key.match(/^custom:meta-field-(\d*)_?(.*)?$/);
  return {
    id: match?.[1],
    lang: match?.[2],
  };
};

/** Converts the API compatible metaById to structured metaValuesById */
export const getMetaValuesById = (
  metaById: Record<string, string>,
  languages: string[]
) => {
  const metaValuesById = {} as Record<string, TranslatedData<string>>;
  Object.keys(metaById).forEach(metaKey => {
    const [key, id, lang] =
      metaKey.match(/^custom:meta-field-(\d*)($|_.{2}$)/) || [];
    if (!key || !id) return;
    if (lang) {
      const existingValue = metaValuesById[id] ?? {};
      metaValuesById[id] = {
        ...existingValue,
        [lang.replace('_', '')]: metaById[metaKey],
      };
    } else {
      metaValuesById[id] = languages.reduce(
        (a, lang) => ({
          ...a,
          [lang]: metaById[metaKey],
        }),
        {}
      );
    }
  });
  return metaValuesById;
};

/** Converts the structured metaValuesById to an API compatible map with meta field keys.
 *
 * **NOTE** If you wish to display metaValuesById on the client,
 * you should use `convertMetaValuesByIdIn` as this function doesn't convert the values */
export const flattenMetaValuesById = (
  metaValuesById: Record<string, TranslatedData<string>>
) =>
  Object.keys(metaValuesById).reduce(
    (metaByKey, metaFieldId) => ({
      ...metaByKey,
      ...Object.keys(metaValuesById[metaFieldId]).reduce(
        (metaByLang, lang) => ({
          ...metaByLang,
          [getMetaFieldKey(metaFieldId, lang)]:
            metaValuesById[metaFieldId][lang],
        }),
        {} as Record<string, string>
      ),
    }),
    {} as Record<string, string>
  );

/** Converts a structured `descriptionsByLang` to an API compatible map with
 * special `description` keys */
export const flattenDescriptionsByLang = (descriptionsByLang: TranslatedData) =>
  flattenLocalizedObject(descriptionsByLang, 'nibo:description');

/** Converts a localized object to an API compatible map with a prefixed key */
export const flattenLocalizedObject = (
  localizedValues: TranslatedData,
  prefix: string
) =>
  Object.keys(localizedValues).reduce(
    (acc, lang) => ({
      ...acc,
      [`${prefix}_${lang}`]: localizedValues[lang],
    }),
    {} as Record<string, string>
  );

export const trimNamePrefix = (name, isFolderName) => {
  const match = name ? name.match(/^[0-9]{1,3}?_(.*)$/) : null;
  return isFolderName && match ? match[1] : name;
};

/* Target can be either a string or for instance a namesByLang object */
export const trimHtmlTags = (target: string | object) => {
  if (typeof target === 'string') return target.replace(/(<([^>]+)>)/gi, '');
  return Object.keys(target).reduce((a, c) => {
    a[c] =
      typeof target[c] === 'string'
        ? target[c].replace(/(<([^>]+)>)/gi, '')
        : target[c];
    return a;
  }, {});
};

export const convertsTexts = (
  textsByLang,
  propertiesById,
  propertyPrefix,
  isFolderName
) => {
  const texts = {};
  if (textsByLang) {
    Object.keys(textsByLang).forEach(lang => {
      texts[lang] = trimNamePrefix(textsByLang[lang], isFolderName);
    });
  } else {
    Object.keys(propertiesById).forEach(key => {
      if (key.startsWith(propertyPrefix)) {
        texts[key.replace(propertyPrefix, '')] = trimNamePrefix(
          propertiesById[key],
          isFolderName
        );
      }
    });
  }
  return texts;
};

export function convertTextsOut(texts: TranslatedData, propertyPrefix: string) {
  return Object.keys(texts).reduce((acc, cur) => {
    if (cur) acc[`${propertyPrefix}${cur}`] = texts[cur];
    return acc;
  }, {});
}

export const convertMetaFieldIn = ({
  valuesByLang,
  valueTree,
  ...field
}: any) => {
  const treeData =
    field.valueType.includes('tree') && valueTree
      ? parseMetaFieldTree(valueTree)
      : undefined;
  const optionsByLang = field.valueType.includes('dropdown')
    ? valuesByLang
    : undefined;
  return {
    ...field,
    namesByLang: trimHtmlTags(field.namesByLang),
    valueTree: treeData?.valueTree,
    tree: treeData?.tree,
    nodesById: treeData?.nodesById,
    optionsByLang,
  } as MetaField;
};

// REFACTOR: Do most of these in server-side API
export const convertTreeElementIn = element => {
  const node = element.node;

  // Convert name
  node.name = trimNamePrefix(node.name, node.folder);
  if (!node.namesByLang) {
    node.namesByLang = convertsTexts(
      node.namesByLang,
      node.propertiesById,
      'nibo:name_',
      node.folder
    );
  }

  node.isFolder = node.folder;
  node.isCart = node.cart;
  node.isMasterProduct = node.masterProduct;
  node.isUserProduct = node.userProduct;
  node.isLink = node.link;
  node.isInherited = node.isInheritNode;

  const isFile =
    !node.isFolder &&
    !node.isCart &&
    !node.isMasterProduct &&
    !node.isUserProduct &&
    !node.isLink &&
    !node.isInheritNode;

  node.isFile = isFile;

  if (element.children && element.children.length) {
    element.children.forEach(el => {
      convertTreeElementIn(el);
    });
  }
};

// REFACTOR: Do most of these in server-side API
export const convertFileIn = (file): File | RemovedFile => {
  if (file.removed)
    return { id: file.id, removed: true, removeTime: file.removeTime };

  const {
    id,
    concreteFileId,
    linkFileId,
    parentId,
    concreteParentId,
    linkParentId,
    concreteCreated,
    linkCreated,
    inCart,
    inShoppingCart,
    isShared,
    name: origName,
    namesByLang: origNamesByLang,
    concreteNamesByLang,
    descriptionsByLang: origDescriptionsByLang,
    uploadInstructionsByLang: origUploadInstructionsByLang,
    propertiesById: origPropertiesById,
    concretePropertiesById,
    info,
    fileType: origFileType,
    concreteFileType,
    linkFileType,
    attachments: origAttachments,
    downloadOptions,
    parents: origParents,
    path,
    concreteFilePath,
    linkFilePath,
    mimeGroup: origMimeGroup,
    concreteMimeGroup,
    metaById,
    linkedFiles,
    node: nodeOld,
    ...rest
  } = file;

  const fileType = concreteFileType || origFileType;
  const mimeGroup = concreteMimeGroup || origMimeGroup;

  const isFile = fileType === 'nt:file';
  const isFolder = fileType === 'nt:folder' || fileType === 'nt:archiveFolder';
  const isCart = fileType === 'nt:cart';
  const isMasterProduct = fileType === 'nt:masterProduct';
  const isUserProduct = fileType === 'nt:userProduct';
  const isInherited = file.isInherited;
  const propertiesById = { ...concretePropertiesById, ...origPropertiesById };

  // Determine and convert texts
  const name = trimNamePrefix(origName, isFolder);
  const namesByLang = convertsTexts(
    { ...concreteNamesByLang, ...origNamesByLang },
    file.propertiesById,
    'nibo:name_',
    isFolder
  );
  const fullNamesByLang = convertsTexts(
    { ...concreteNamesByLang, ...origNamesByLang },
    file.propertiesById,
    'nibo:name_',
    false
  );
  const descriptionsByLang = convertsTexts(
    origDescriptionsByLang,
    propertiesById,
    'nibo:description_',
    false
  );
  const uploadInstructionsByLang = convertsTexts(
    origUploadInstructionsByLang,
    file.propertiesById,
    'nibo:uploadInfo_',
    false
  );
  // const instructionsByLang = convertsTexts(
  //   info.contents,
  //   file.propertiesById,
  //   'nibo:instruction_',
  //   false
  // );

  const fileCount = propertiesById['nibo:file-count']
    ? Number(propertiesById['nibo:file-count'])
    : undefined;
  const fileCountAll = propertiesById['nibo:file-count-all']
    ? Number(propertiesById['nibo:file-count-all'])
    : undefined;

  const instructionsByLang = info && info.contents;
  const instructionImageUuid = info && info.imageUuid;
  // Convert texts of parents
  const parents =
    origParents &&
    origParents.map(parent => {
      return {
        ...parent,
        namesByLang: convertsTexts(parent.namesByLang, null, null, true),
      };
    });
  /** metaValuesById: customer metadata of file or inheritable folder 
     The inheritable folder: isInheritNode=true (nibo:folder-custom-metadata-model=true) => is folder that can inherit own metadata to childs if customUploadLayout > 0
     The folder is not inheritable (isInheritNode=false) and  customUploadLayout=0 => it inherited custom metadata from some up level parent inheritable folder, and then custom metadata in inheritedMetaById property
     If customUploadLayout is undefined => inheritance to childs not allowed
  */
  const inheritableMetaById =
    isFolder && file.isInheritNode && metaById
      ? getMetaValuesById(metaById, Object.keys(file.namesByLang))
      : {};
  const metaValuesById =
    (isFile || isMasterProduct || isUserProduct) && metaById
      ? getMetaValuesById(metaById, Object.keys(file.namesByLang))
      : {};

  // combine attachments and download options and remove duplicates
  const concatinatedAttachments = [
    ...(origAttachments || []),
    ...(downloadOptions || []),
  ];
  const attachments: FileAttachment[] | undefined = origAttachments
    ? concatinatedAttachments
        .filter(
          (x, i) => concatinatedAttachments.findIndex(y => y.id === x.id) === i
        )
        .map(file => ({
          ...file,
          downloadable:
            !!downloadOptions && downloadOptions.some(x => x.id === file.id),
        }))
    : undefined;

  const node: Node = {
    id: linkFileId,
    path: linkFilePath,
    fileType: linkFileType,
    parentId: linkParentId,
    parents,
    isLink:
      linkFileType === 'nt:linkedFile' || linkFileType === 'nt:linkedFolder',
    inCart,
    inShoppingCart,
    isShared,
    created: linkCreated,
  };
  const concrete: ConcreteNode = {
    id: concreteFileId,
    path: concreteFilePath,
    fileType: concreteFilePath,
    parentId: concreteParentId,
    created: concreteCreated,
  };

  const workspaceShare: WorkspaceShare | undefined = isCart
    ? {
        sharing: propertiesById['nibo:sharing'],
        publiclySharedEndTime: propertiesById['nibo:publicly-shared-endTime'],
        publiclySharedStartTime:
          propertiesById['nibo:publicly-shared-startTime'],
        hasPublicSharingValidityPeriod:
          propertiesById['nibo:has-public-sharing-validity-period'] === 'true',
      }
    : undefined;

  // The Api should only return the user's likes
  const likedByUserKey = Object.keys(propertiesById).find(key =>
    key.startsWith('nibo:liked-by-user-')
  );
  const isLikedByUser =
    Boolean(likedByUserKey) &&
    propertiesById[likedByUserKey as string] === 'true';

  return {
    node,
    concrete,
    path,
    name,
    namesByLang,
    fullNamesByLang,
    uploadInstructionsByLang,
    descriptionsByLang,
    instructionsByLang,
    propertiesById,
    instructionImageUuid,
    isFile,
    isFolder,
    isCart,
    isMasterProduct,
    isUserProduct,
    isInherited,
    metaValuesById,
    inheritableMetaById,
    mimeGroup,
    fileCount,
    fileCountAll,
    isLikedByUser,
    ...rest,
    attachments,
    removed: false,
    workspaceShare,
    linkedFiles: linkedFiles?.map(convertFileIn),
  };
};

const reduceToAllLangs = (value: any) =>
  ALL_CUSTOMER_LANGUAGES.reduce(
    (a, lang) => ({
      ...a,
      [lang]: value,
    }),
    {}
  );

export const convertContentToFile = (
  content: Content | WorkspaceContent
): File => {
  const isFile = content.fileType === 'file';
  const isFolder = content.fileType === 'folder';
  const isCart = content.fileType === 'cart';
  const isMasterProduct = content.fileType === 'masterProduct';
  const isUserProduct = content.fileType === 'userProduct';
  const isLink = content.entryType === 'link';
  const isShared = content.isShared;
  const isInherited = false; // TODO

  const namesByLang = reduceToAllLangs(content.name);
  const altTextsByLang = reduceToAllLangs(content.alt);
  const descriptionsByLang = reduceToAllLangs(content.description);

  const workspaceShare: WorkspaceShare | undefined =
    'sharing' in content
      ? ({
          sharing: content.sharing,
          hasPublicSharingValidityPeriod:
            content.hasPublicSharingValidityPeriod,
          publiclySharedEndTime: content.publiclySharedEndTime,
          publiclySharedStartTime: content.publiclySharedStartTime,
          ownerId: Number(content.owner),
        } as WorkspaceShare)
      : undefined;

  const metaValuesById =
    isFile && content.metaById
      ? getMetaValuesById(content.metaById, [...ALL_CUSTOMER_LANGUAGES])
      : {};

  return Object.assign(
    content?.object || {}, // content.object contains File properties not present here. Merge if exists, but prefer values below
    {
      node: {
        id: content.id,
        parentId: content.parentId,
        path: '',
        inCart: content.location === 'cart',
        inShoppingCart: false,
        isLink,
        fileType: `nt:${content.fileType}` as FileType,
        isShared,
        created: content.created,
      },
      name: content.name,
      namesByLang,
      fullNamesByLang: namesByLang,
      altTextsByLang,
      uploadInstructionsByLang: {},
      descriptionsByLang,
      apiLink: content.apiLink,
      apiContentLink: content.apiContentLink,
      url: content.url,
      emails: content.emails,
      emailCount: content.emailCount,
      isFile,
      isFolder,
      isCart,
      isMasterProduct,
      isUserProduct,
      isInherited,
      metaValuesById,
      mimeGroup: content.mimeGroup,
      attachments: undefined,
      modified: content.modified,
      indicateSynkka: content.indicateSynkka,
      inheritedPropertiesById: {},
      inheritedMetaById: {},
      inheritableMetaById: {},
      propertiesById: {
        'nibo:mime-type': content.mimeType,
      },
      removed: false,
      workspaceShare,
      thumbnails: content.thumbnails,
      isLikedByUser: content.isLikedByUser,
    }
  ) as File;
};

// REFACTOR: Do most of these in server-side API
export const convertFilesIn = (files: any[]) => files.map(convertFileIn);

// REFACTOR: Do most of these in server-side API
export const convertContentToFiles = (contents: Content[]) =>
  contents.map(convertContentToFile);

// REFACTOR: Do most of these in server-side API
const convertSearchParameter = (key: string, value: any) => {
  // Convert meta field values of type 'multiple' to the sha1 format
  if (
    key.startsWith('f-meta-') &&
    key.endsWith('_multiple') &&
    value instanceof Array
  ) {
    return (value as Array<string>).reduce(
      (result, value) => `${result ? result + ' ' : ''}${sha1(value)}`,
      ''
    );
  }
  return value;
};

// REFACTOR: Do most of these in server-side API
export const convertSearchParameters = params => {
  const converted = {};
  Object.keys(params).forEach(key => {
    converted[key] = convertSearchParameter(key, params[key]);
  });
  return converted;
};

const capitalize = s => {
  if (typeof s !== 'string') return '';
  return s.charAt(0).toUpperCase() + s.slice(1);
};

export const getFileExtension = (item: File) => {
  return item && item.isFile && item.name.indexOf('.') !== -1
    ? item.name.split('.').pop()
    : null;
};

const shortenType = (type: string) => {
  return type ? type.split('.')[0].substring(0, 14) : type;
};

const getMimeType = (item: File) => {
  const mimeType = item.propertiesById && item.propertiesById['nibo:mime-type'];
  return (
    mimeType &&
    shortenType(
      mime.getExtension(mimeType) || capitalize(mimeType.split('/').pop())
    )
  );
};

export const getItemFormat = (item?: File) => {
  if (!item) return '';
  if (item.isFolder) return t`Folder`;
  if (item.isCart) return t`Workspace`;
  if (item.isMasterProduct || item.isUserProduct) {
    return item.propertiesById['nibo:mime-type'] === 'text/html'
      ? t`HTML template`
      : t`Template`;
  }
  if (item.isFile) {
    return getMimeType(item) || getFileExtension(item);
  }
  return null;
};

export enum AllowLevel {
  ForAll = 2,
  ForSome = 1,
  ForNone = 0,
}

/**
 * Check if given operation is allowed for each of `checkedContent`.
 *
 * Sometimes you might want to omit folders from the check with the boolean
 * argument, e.g. when working with workspace contents.
 */
export const allowLevel = (
  right: keyof Required<File>['userRights'],
  checkedContent: Set<string> | string[],
  filesById: FilesById,
  skipFolders = false
) => {
  let validFound = false;
  let invalidFound = false;

  if (!checkedContent || !filesById) return AllowLevel.ForNone;

  let files = Array.from(checkedContent).map(id => filesById[id]?.file);
  if (skipFolders) files = files.filter(f => !f?.isFolder);

  files.forEach(file => {
    const ok = file?.userRights?.[right];
    validFound = validFound || !!ok;
    invalidFound = invalidFound || !ok;
  });

  if (validFound && !invalidFound) return AllowLevel.ForAll;
  if (validFound && invalidFound) return AllowLevel.ForSome;
  return AllowLevel.ForNone;
};

export const getFileName = (
  file: File,
  language: string,
  defaultLanguage: string
) => {
  return file.namesByLang
    ? file.namesByLang[language] ||
        file.namesByLang[defaultLanguage] ||
        file.name
    : file.name;
};

export function getOriginalUrl(item: File) {
  return `${config.apiUrl}/files/${
    item.node.id
  }/contents/original${getShareKeyParameter()}`;
}

const getDefaultSort = (
  folderSort: boolean,
  customerConfig?: Record<string, unknown>
) =>
  (folderSort
    ? customerConfig?.['folder.browse.settings.default.sort'] || 'name'
    : customerConfig?.['search.browse.settings.default.sort'] ||
      'default') as string;

// ////////////////// //
// Criteria utilities //
// ////////////////// //

function parseFolderId(pathname: string) {
  return pathname.match(/(folders|workspaces|archive|shopping)\/(\d*)/)?.[2] as
    | `${number}`
    | undefined;
}

/** Returns the ID of currently opened folder as parsed from the URL */
export function useCurrentFolderId() {
  const { pathname } = useLocation();
  return parseFolderId(pathname);
}

/** Should only be used inside sagas, for components use `useCurrentFolderId()` */
export function getCurrentFolderId() {
  return parseFolderId(window.location.pathname);
}

function toInt(criteria: Record<string, unknown>, key: string) {
  const value = criteria[key] as string;
  if (value !== undefined) {
    criteria[key] = parseInt(value, 10);
  }
}

export function parseCriteria(queryString: string) {
  const params =
    queryString && queryString.startsWith('?')
      ? queryString.substring(1)
      : queryString;
  const criteria = qs.parse(params);
  // Include only relevant keys
  Object.keys(criteria)
    .filter(key => key.toLocaleLowerCase() === 'lang')
    .forEach(key => delete criteria[key]);

  const parsedTags = criteria.tags && parseTags(criteria.tags as string);

  // NOTE: We modify object directly to avoid adding undefined values to object
  toInt(criteria, 'page');
  toInt(criteria, 'pageSize');
  toInt(criteria, 'selectedIndex');
  return { ...(criteria as Partial<Criteria>), tags: parsedTags || [] };
}

function getDefaultCriteria(
  customerConfig: Record<string, unknown>,
  innerWidth: number,
  isSearch: boolean,
  isShoppingCart: boolean,
  userFolderSort?: string,
  userSearchSort?: string
) {
  const defaultPageSize =
    (customerConfig &&
      Number.parseInt(
        customerConfig['content.browse.settings.pagesize'] as string
      )) ||
    50;

  return {
    page: 0,
    // No paging in shopping cart, large pages on mobile
    pageSize: isShoppingCart ? -1 : innerWidth > 630 ? defaultPageSize : 200,
    selectedId: null,
    selectedIndex: -1,
    sortBy: isSearch
      ? userSearchSort ?? getDefaultSort(false, customerConfig)
      : userFolderSort ?? getDefaultSort(true, customerConfig),
  };
}

export function useCriteria(): Criteria {
  const windowSize = useWindowSize();
  const customerConfig = useSelector(state => state.app.customer?.configById);
  const userFolderSort = useSelector(state => state.app.userFolderSort);
  const userSearchSort = useSelector(state => state.app.userSearchSort);
  const currentFolderId = useCurrentFolderId();
  const location = useLocation();
  const { mode } = useContentMode();

  const defaultCriteria = getDefaultCriteria(
    customerConfig ?? {},
    windowSize.innerWidth,
    !currentFolderId,
    mode === 'shopping',
    userFolderSort,
    userSearchSort
  );

  const parsedCriteria = parseCriteria(location.search);

  const memoedCriteria = useMemo(
    () => ({ ...defaultCriteria, ...parsedCriteria }),
    [location.search, windowSize.innerWidth > 630, currentFolderId]
  );

  return memoedCriteria;
}

/** Should only be used inside sagas, for components use `useCriteria()` */
export function getCriteria(): Criteria {
  const customerConfig = getCustomerConfig();

  const defaultCriteria = getDefaultCriteria(
    customerConfig ?? {},
    window.innerWidth,
    window.location.pathname === '/search',
    window.location.pathname.startsWith('/shopping'),
    getUserFolderSort(),
    getUserSearchSort()
  );

  const parsedCriteria = parseCriteria(window.location.search);

  return { ...defaultCriteria, ...parsedCriteria };
}

type CriteriaParams = Omit<Criteria, 'tags' | 'sortBy'> & {
  tags?: string;
  sortBy?: string;
};

export const updateBrowseCriteria = (
  pathPrefix: string,
  enterMode: EnterMode,
  historyUpdateMode: string | null,
  criteria: CriteriaParams
) => {
  const browseMode = enterMode
    ? enterMode === 'browse'
    : window.location.pathname.indexOf('/browse') !== -1 &&
      criteria &&
      criteria.selectedIndex !== -1 &&
      criteria.selectedIndex !== undefined;
  const pathSuffix = browseMode ? '/browse' : '';

  const params = {
    pathname: `${pathPrefix}${pathSuffix}`,
    search: criteria ? `?${qs.stringify(criteria)}` : '',
  };

  const mode =
    historyUpdateMode ||
    (browseMode && enterMode !== 'browse' ? 'replace' : 'push');
  if (mode === 'push') {
    history.push(params);
  } else if (mode === 'replace') {
    history.replace(params);
  } else if (mode === 'back') {
    history.goBack();
    history.replace(params);
  }
};

export function updateContentCriteria(
  id: string | undefined,
  criteria: Partial<Criteria>,
  historyUpdateMode: string | null = null,
  enterMode: EnterMode | null = null,
  pathPrefix?: string
) {
  const pathname = window.location.pathname;

  const customerConfig = getCustomerConfig();
  const prevCriteria = getCriteria();
  const criteriaParams = {
    ...criteria,
    // preserve the sortBy unless specified explicitly
    sortBy: convertSortOption(
      criteria.sortBy || prevCriteria?.sortBy,
      !!id,
      customerConfig
    ),
  } as unknown as CriteriaParams;

  if (criteria.tags && Array.isArray(criteria.tags)) {
    criteriaParams.tags = encodeTags(criteria.tags);
  }

  let finalPathPrefix: string;
  let pathId: string;
  if (!id) {
    finalPathPrefix = '/search';
    pathId = '';
  } else if (pathPrefix) {
    finalPathPrefix = pathPrefix;
    pathId = `/${id}`;
  } else if (pathname.indexOf('/workspaces') !== -1) {
    finalPathPrefix = '/workspaces';
    pathId = `/${id}`;
  } else if (pathname.indexOf('/archive') !== -1) {
    finalPathPrefix = '/archive';
    pathId = `/${id}`;
  } else if (pathname.indexOf('/shopping') !== -1) {
    finalPathPrefix = '/shopping';
    pathId = `/${id}`;
  } else {
    finalPathPrefix = '/folders';
    pathId = `/${id}`;
  }

  updateBrowseCriteria(
    `${finalPathPrefix}${pathId}`,
    enterMode,
    historyUpdateMode,
    criteriaParams
  );
}

export function getSubtitleMetaId(metaFields: MetaField[]) {
  return metaFields.find(
    field => field.settings?.['VideoContentAnalysisSubtitles:subtitles']
  )?.id as string | undefined;
}

export const metaFieldHasInfo = (
  metaFieldId: string,
  metaFields: MetaField[],
  customerInfoTextsByLang?: {
    descriptionInfoByLang?: TranslatedData;
    instructionInfoByLang?: TranslatedData;
  }
) => {
  if (metaFieldId === DESCRIPTION_FIELD_ID) {
    return (
      customerInfoTextsByLang &&
      customerInfoTextsByLang.descriptionInfoByLang &&
      Object.keys(customerInfoTextsByLang.descriptionInfoByLang).length > 0
    );
  }
  if (metaFieldId === INSTRUCTION_FIELD_ID) {
    return (
      customerInfoTextsByLang &&
      customerInfoTextsByLang.instructionInfoByLang &&
      Object.keys(customerInfoTextsByLang.instructionInfoByLang).length > 0
    );
  }
  return metaFields.some(
    field =>
      field.id === metaFieldId &&
      field.infoTextByLang &&
      Object.keys(field.infoTextByLang).length > 0
  );
};
// config.customer === 'metsahallitus' && metaFieldId === '187';

export interface TreeNode extends MetaFieldTreeNode {
  children: TreeNode[];
  parentIds: string[];
}

interface TreeData {
  tree: TreeNode;
  nodesById: Record<string, TreeNode>;
  valueTree: MetaFieldTree;
}

export const parseMetaFieldTree = (
  valueTree?: MetaFieldTree
): TreeData | undefined => {
  if (!valueTree) return undefined;
  const nodesById: Record<string, TreeNode> = {};
  const rootItem = valueTree.itemsById[valueTree.rootItemId];

  const traverse = (
    parent: MetaFieldTreeNode,
    parentIds: string[] = []
  ): TreeNode => {
    const children: TreeNode[] = [];
    for (const id of parent.childrenIds) {
      const child = valueTree.itemsById[id];
      if (!child) {
        continue;
      }
      children.push(traverse(child, [...parentIds, parent.id]));
    }
    const node = {
      ...parent,
      children,
      parentIds,
    };
    nodesById[node.id] = node;
    return node;
  };

  const root = traverse(rootItem);
  return { tree: root, nodesById, valueTree };
};

export const getNodePath = (
  nodeId: string,
  language: string,
  nodesById: Record<string, TreeNode> | undefined,
  maxLength?: number
) => {
  if (!nodesById || !nodesById[nodeId]) {
    return { fullPath: '', shortPath: '' };
  }
  const parents = nodesById[nodeId].parentIds.map(id => nodesById[id]);
  const nodes = [...parents, nodesById[nodeId]];

  const pathParts = nodes
    .map(node => {
      // The node may not have a name in every language -> just use something
      return getLangValue(node.namesByLang, language);
    })
    .filter(Boolean);

  let [, shortPathCount] = maxLength
    ? pathParts
        .map(x => x.length as number)
        .reduceRight(
          ([aLength, aCount, end], length) => {
            if (!end && aLength + length + 1 < maxLength)
              return [aLength + length + 1, aCount + 1, false];
            return [aLength, aCount, true];
          },
          [0, 0, false]
        )
    : [Infinity, Infinity];

  // include at least one
  if (!shortPathCount) shortPathCount = 1;

  const fullPath = `/${pathParts.join('/')}`;
  // shorten the path to .../tail if too long
  const shortPath = `${
    shortPathCount < pathParts.length ? '.../' : '/'
  }${pathParts.slice(-shortPathCount).join('/')}`;
  return { fullPath, shortPath };
};

export const validateNode = (
  nodeId: string,
  metaField: Pick<TreeMetaField, 'valueTree'>
) => {
  if (!metaField.valueTree) {
    return;
  }

  return Boolean(metaField.valueTree.itemsById[nodeId]);
};

const searchSpecificOptions = ['default'];
const folderSpecificOptions = ['description', '-description'];
const commonSortOptions = [
  {
    folder: 'name',
    search: 'orderByName',
  },
  {
    folder: '-name',
    search: '-orderByName',
  },
  {
    folder: '-created',
    search: 'orderByCreated',
  },
  {
    folder: 'created',
    search: '-orderByCreated',
  },
  {
    folder: '-modified',
    search: 'orderByLastUsed',
  },
  {
    folder: 'modified',
    search: '-orderByLastUsed',
  },
  {
    folder: '-custom_field',
    search: 'orderByCustomMeta',
  },
  {
    folder: 'custom_field',
    search: '-orderByCustomMeta',
  },
];

/* convert sort options from search to folder views and vice versa */
export const convertSortOption = (
  value: string | undefined,
  folderSort: boolean,
  customerConfig?: Record<string, unknown>
) => {
  const defaultValue = getDefaultSort(folderSort, customerConfig);
  const option = commonSortOptions.find(
    x => x.folder === value || x.search === value
  );
  // when switching to search, use default sort that's missing on folders
  if (
    !folderSort &&
    !searchSpecificOptions.includes(value ?? '') &&
    (!option || option.search !== value)
  )
    return defaultValue;
  // if common option found, return that
  if (option) return folderSort ? option?.folder : option?.search;
  // if value is a valid sort for the content, return that
  if (
    value &&
    ((folderSort && folderSpecificOptions.includes(value)) ||
      (!folderSort && searchSpecificOptions.includes(value)))
  )
    return value;
  // return the content types default sort
  return defaultValue;
};

/** Checks whether a file or a folder is inside a workspace */
export function isInsideWorkspace(file: File): boolean;
export function isInsideWorkspace(
  baseFile: BaseFile,
  parents: BaseFile[]
): boolean;
export function isInsideWorkspace(
  file: File | BaseFile,
  parents: BaseFile[] | undefined = undefined
): boolean {
  let nodeParents: BaseFile[] = [];
  if (parents !== undefined) nodeParents = parents;
  else if ('node' in file) nodeParents = file.node.parents ?? [];

  for (const parent of nodeParents) {
    if (parent.fileType === 'nt:cart') return true;
  }

  // fallback to checking path if no parents found
  if (
    !nodeParents.length &&
    'node' in file &&
    (file.node.path?.match(/\/_customers\/[^/]*\/_carts\//) ?? null) !== null
  ) {
    return true;
  }

  return false;
}

/** Checks whether a file is contained in workspaces, i.e. it is
 * contained within the magic folder `_carts` */
export function isInWorkspaces(file: File): boolean;
export function isInWorkspaces(
  baseFile: BaseFile,
  parents: BaseFile[]
): boolean;
export function isInWorkspaces(
  file: File | BaseFile,
  parents: BaseFile[] | undefined = undefined
): boolean {
  if (file.name === '_carts') return true;

  let nodeParents: BaseFile[] = [];
  if (parents !== undefined) nodeParents = parents;
  else if ('node' in file) nodeParents = file.node.parents ?? [];

  for (const parent of nodeParents) {
    if (parent.name === '_carts') return true;
  }
  return false;
}

/** Checks whether a file is archived, i.e. it's in the _archive path */
export function isInArchives(file: File): boolean;
export function isInArchives(baseFile: BaseFile, parents: BaseFile[]): boolean;
export function isInArchives(
  file: File | BaseFile,
  parents: BaseFile[] | undefined = undefined
): boolean {
  if (file.name === '_archive') return true;

  let nodeParents: BaseFile[] = [];
  if (parents !== undefined) nodeParents = parents;
  else if ('node' in file) nodeParents = file.node.parents ?? [];

  for (const parent of nodeParents) {
    if (parent.name === '_archive') return true;
  }
  return false;
}

/** Merges two files and their metadata, overwriting properties of
 * `a` with properties of `b` if any in common. */
export function mergeFiles(
  a: File | undefined,
  b: File,
  overwriteStateMeta?: boolean
): File {
  const metaValuesById = overwriteStateMeta
    ? b?.metaValuesById
    : merge(a?.metaValuesById, b?.metaValuesById);
  return {
    ...(a ?? {}),
    ...(b ?? {}),
    metaValuesById,
  };
}

/** Merges a new file into a `filesById` mapping */
export function mergeIntoFilesById(
  file: File,
  filesById: FilesById,
  commonProperties: Omit<FileContainer, 'file'>,
  overwriteStateMeta?: boolean
): FilesById {
  return {
    ...filesById,
    [file.node.id]: {
      ...commonProperties,
      file: {
        ...mergeFiles(filesById[file.node.id]?.file, file, overwriteStateMeta),
      },
    },
  };
}

/** Merges a new removed file into a `removedItemsById` mapping */
export function mergeIntoRemovedItemsById(
  file: RemovedFile,
  removedItemsById: RemovedItemsById,
  commonProperties: Omit<FileContainer, 'file'>
): RemovedItemsById {
  return {
    ...removedItemsById,
    [file.id]: {
      ...commonProperties,
      item: file,
    },
  };
}

/** Merges a file or a removed file into state-like object */
export function mergeFileIntoState<
  T extends { filesById: FilesById; removedItemsById: RemovedItemsById }
>(
  file: File | RemovedFile,
  state: T,
  commonProperties: Omit<FileContainer, 'file'>,
  overwriteStateMeta?: boolean
): T {
  return {
    ...state,
    ...(file.removed
      ? {
          removedItemsById: mergeIntoRemovedItemsById(
            file,
            state.removedItemsById,
            commonProperties
          ),
        }
      : {
          filesById: mergeIntoFilesById(
            file,
            state.filesById,
            commonProperties,
            overwriteStateMeta
          ),
        }),
  };
}

export function getConversions(folder: File) {
  return [
    ...(folder.propertiesById[conversionKeys.highRes] === 'true'
      ? [conversionKeys.highRes]
      : []),
    ...(folder.propertiesById[conversionKeys.webImg] === 'true'
      ? [conversionKeys.webImg]
      : []),
    ...(folder.propertiesById[conversionKeys.pdfLow] === 'true'
      ? [conversionKeys.pdfLow]
      : []),
    ...(folder.propertiesById[conversionKeys.imageProfiles] ?? '')
      .split(',')
      .filter(v => v) // skip empty value
      .map(v => `imageConvertProfiles.${v}`),
    ...(folder.propertiesById[conversionKeys.videoProfiles] ?? '')
      .split(',')
      .filter(v => v) // skip empty value
      .map(v => `videoConvertProfiles.${v}`),
    ...(folder.propertiesById[conversionKeys.audioProfiles] ?? '')
      .split(',')
      .filter(v => v) // skip empty value
      .map(v => `audioConvertProfiles.${v}`),
  ];
}

export function getWorkflowsIds(folder: File): number[] | undefined {
  return (folder.propertiesById['nibo:workflow-ids'] ?? '')
    .split(' ')
    .filter(v => v) // skip empty value
    .map(v => Number(v));
}

export function getNoticationGroupIds(folder: File): number[] | undefined {
  return (folder.propertiesById['nibo:folder-notification-groups'] ?? '')
    .split(', ')
    .filter(v => v) // skip empty value
    .map(v => Number(v));
}

export function getNoticationUserIds(folder: File): string[] | undefined {
  return (folder.propertiesById['nibo:folder-notification-users'] ?? '')
    .split(', ')
    .filter(v => v); // skip empty value
}

export function getNotificationGroupEmail(folder: File): boolean | undefined {
  return (
    folder.propertiesById['nibo:folder-notification-type-email'] === 'true' ??
    false
  );
}

export function getNotificationUserEmail(folder: File): boolean | undefined {
  return (
    folder.propertiesById['nibo:folder-user-notification-type-email'] ===
      'true' ?? false
  );
}

export function getNotificationTypeWeb(folder: File): boolean | undefined {
  return (
    folder.propertiesById['nibo:folder-notification-type-web'] === 'true' ??
    false
  );
}

export function getBasketShowOnFrontpage(folder: File): boolean | undefined {
  return (
    folder.propertiesById['nibo:basket-show-on-frontpage'] === 'true' ?? false
  );
}

export function getBasketOnFrontpageStickyBit(
  folder: File
): boolean | undefined {
  return (
    folder.propertiesById['nibo:basket-on-frontpage-sticky-bit'] === 'true' ??
    false
  );
}

export function getDenyCrawlerBot(folder?: File): boolean | undefined {
  return (
    folder?.propertiesById['nibo:basket-deny-crawler-bot'] === 'true' ?? false
  );
}

export function getBasketIsCampaign(folder: File): boolean | undefined {
  return folder.propertiesById['nibo:basket-is-campaign'] === 'true' ?? false;
}

/** Different meta fields in the application can be grouped, but the API
 * only ever returns all metafields as a flat list. To infer groupings from
 * given list, we must find a special "metafield" with a `fieldType` of
 * 'separator', and then all regular fields following the 'separator should
 * be considered to be in a group named by the 'separator'. When another
 * 'separator' comes up, it starts a new group.
 *
 * This function takes the bare list of metafields, and parses it into a list
 * of metafield group objects, where the grouped fields are included as a
 * nested list for much more ergonomic use later on.
 *
 * The first metafields returned by the API usually don't have a 'separator'
 * preceding them, so they belong to no group. In this case, we just group them
 * into a metafield group with an id of empty string, and without a `namesByLang`
 */
export function getMetaFieldGroups(metaFields: MetaField[]) {
  const { metaFieldsByGroup } = metaFields.reduce(
    ({ currentSeparator, metaFieldsByGroup }, cur) => {
      if (cur.valueType === 'separator') {
        return {
          metaFieldsByGroup,
          currentSeparator: cur.id,
        };
      }
      return {
        currentSeparator,
        metaFieldsByGroup: {
          ...metaFieldsByGroup,
          [currentSeparator]: [
            ...(metaFieldsByGroup[currentSeparator] ?? []),
            cur,
          ],
        },
      };
    },
    {
      currentSeparator: '',
      metaFieldsByGroup: {} as Record<string, MetaField[]>,
    }
  );

  return Object.entries(metaFieldsByGroup)
    .map(([id, fields]) => {
      const separator = metaFields.find(field => field.id === id);

      return separator
        ? {
            id: separator.id,
            namesByLang: separator.namesByLang,
            placement: separator.placement,
            metaFields: fields,
          }
        : {
            id: '',
            placement: 0,
            metaFields: fields,
          };
    })
    .sort((a, b) => a.placement - b.placement);
}

type UseCustomerMetaFieldsParams = { shareKey: string } | void;
/** Fetches and returns the meta fields configured for the current customer.
 * When the metafields are loading, an empty array is returned
 */
export function useCustomerMetaFields(params: UseCustomerMetaFieldsParams) {
  const customerId = useSelector(state => state.app.customer?.id);
  const { data: metaFields = [] } = useGetCustomerMetaFieldsQuery(
    customerId ? { customerId, shareKey: params?.shareKey } : skipToken
  );

  return metaFields;
}

export const filterItemIds = (
  itemIds: string[],
  conflictStrategy: HandleFileConflict,
  conflictIds: string[]
): string[] => {
  if (conflictStrategy === 'skip') {
    return itemIds.filter(id => !conflictIds.includes(id));
  }
  return itemIds;
};
