import { useEffect, useRef } from 'react';
import qs from 'qs';
import config from '~common/config';
import { getShareKeyValue } from '~common/content.api';
import { File } from '~common/content.types';

/** Preloads image sources for them to be available in browser's memory */
export function usePreloadedImages(sources: string[]) {
  const images = useRef<HTMLImageElement[]>();

  useEffect(() => {
    images.current = sources.map(src => {
      const img = new Image();
      img.src = src;
      return img;
    });
  }, [sources.join(' ')]);
}

/** Constructs a cacheable source to display a thumbnail or a preview of an item.
 * NOTE: Always try to use this version rather than `getUncachedImageSrc` if the item is known.
 */
export function getImageSrc(item: File, type: 'thumbnail' | 'preview') {
  if (item.previewInProcessing) {
    return getNotFoundImgSrc('dark');
  }
  const cacheKey = item.modified ?? item.concrete?.created;

  return getImageSrcBase(item.node.id, type, {
    // If the preview/thumbnail refers to another node, we can't cache the image
    // since we don't know when the preview node has been modified
    versioId: !item.propertiesById?.['nibo:preview-uuid']
      ? cacheKey
      : undefined,
    // ignoreIcon should not be needed anymore due to fixed caching when browsing content
    // ignoreIcon: type === 'thumbnail' ? true : undefined,
    shareKey: getShareKeyValue(),
  });
}

/** Constructs an uncached source from id since we can't determine the freshness just from id.
 * NOTE: Use this version if it's not convenient to use `getImageSrc`
 */
export function getUncachedImageSrc(
  id: string | number,
  type: 'thumbnail' | 'preview',
  params?: Record<string, unknown>
) {
  return getImageSrcBase(id, type, {
    ...params,
    // ignoreIcon should not be needed anymore due to fixed caching when browsing content
    // ignoreIcon: type === 'thumbnail' ? true : undefined,
    shareKey: getShareKeyValue(),
  });
}

/** Constructs a preview/thumbnail source with no additional parameters unless defined */
export function getImageSrcBase(
  id: string | number,
  type: 'thumbnail' | 'preview',
  params?: Record<string, unknown>
) {
  const queryString = params ? `?${qs.stringify(params)}` : '';
  return `${config.apiUrl}/files/${id}/contents/${type}${queryString}`;
}

/** Constructs the source for the 'Not Found' icon */
export function getNotFoundImgSrc(variant?: 'dark') {
  return `${config.assetsPath}notfound${variant === 'dark' ? '_dark' : ''}.svg`;
}
