import { Box, FormControl, FormLabel } from '@mui/material';
import styled from 'styled-components';
import { ContentState, convertToRaw, EditorState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { debounce } from 'lodash';
import Column from '~common/layout/Column';
import CustomFormLabel from '~common/inputs/FormLabel';
import { LocalizedText } from '~common/app.utils';

interface Props {
  id: string;
  initialValue?: string | null;
  onChange: (value: string) => void;
  labelText?: string | LocalizedText;
  readOnly?: boolean;
}

function createEditorState(html?: string | null) {
  if (html === undefined || html === null) return EditorState.createEmpty();
  const blocksFromHTML = htmlToDraft(html);
  const content = ContentState.createFromBlockArray(
    blocksFromHTML.contentBlocks,
    blocksFromHTML.entityMap
  );
  const editorState = EditorState.moveSelectionToEnd(
    EditorState.createWithContent(content)
  );
  return editorState;
}

/** A WYSIWYG Editor
 *
 *
 * About the controllability:
 *
 * Creating a new editor state with every value change will fuck up the cursor position
 * and we don't want to expose EditorState to components, so we will only allow components
 * to initialize the value and listen to changes
 *
 * The initialized value is the first defined value, so if the initial value is being fetched,
 * setting initialValue to undefined will allow initializing the state later on. */
const EditorField = ({
  id,
  initialValue,
  onChange,
  labelText,
  readOnly,
}: Props) => {
  const [editorState, setEditorState] = useState(
    createEditorState(initialValue)
  );

  // When this is set, editorState won't be initialized from string anymore
  const initializerString = useRef(initialValue);
  useEffect(() => {
    if (initialValue === undefined || initializerString.current !== undefined)
      return;
    setEditorState(createEditorState(initialValue));
    initializerString.current = initialValue;
  }, [initialValue]);

  // Low effort perf improvement since EditorField is uncontrolled component
  const debouncedOnChange = useCallback(
    debounce((html: string) => onChange(html), 500),
    [onChange]
  );

  const handleChange = (state: EditorState) => {
    setEditorState(state);
    const rawContentState = convertToRaw(state.getCurrentContent());
    const html = rawContentState.blocks.some(x => !!x.text)
      ? draftToHtml(rawContentState)
      : '';
    // Make sure the field is considered initialized after user starts typing (prevents cursor jumps)
    if (initializerString.current === undefined)
      initializerString.current = html;
    debouncedOnChange(html);
  };

  const editorReference = useRef<HTMLElement>();

  return (
    <Column padding={0}>
      <Box>
        <StyledFormControl>
          <StyledWrapper>
            {labelText && (
              <FormLabel
                id={`${id}-label`}
                onClick={() => editorReference.current?.focus()}
              >
                <CustomFormLabel label={labelText} compact />
              </FormLabel>
            )}
            <Editor
              editorRef={el => (editorReference.current = el)}
              editorState={editorState}
              onEditorStateChange={handleChange}
              ariaLabelledBy={`${id}-label`}
              ariaLabel=" "
              id={id}
              toolbar={{
                options: ['inline', 'list', 'textAlign', 'link', 'history'],
                inline: {
                  options: ['bold', 'italic', 'underline', 'strikethrough'],
                },
                list: { options: ['unordered', 'ordered'] },
                link: {
                  popupClassName: 'EditorLink',
                  defaultTargetOption: '_blank',
                },
              }}
              stripPastedStyles
              readOnly={readOnly}
              toolbarHidden={readOnly}
            ></Editor>
          </StyledWrapper>
        </StyledFormControl>
      </Box>
    </Column>
  );
};

const StyledFormControl = styled(FormControl)`
  width: 100%; // We want this to grow to fill available space
`;

//  This wrapper div is used for overriding styles of the Editor component's inner elements
const StyledWrapper = styled.div`
  min-width: 10rem;

  div.rdw-editor-main {
    border: 1px solid ${p => p.theme.palette.grey[400]};
    min-height: 150px;
    background-color: ${p => p.theme.palette.common.white};
    border-radius: 0px 0px 4px 4px;
    cursor: text;
    max-height: 250px;
  }

  // inherit rdw-editor-main min-height to allow focus on iOS
  div.DraftEditor-root {
    min-height: inherit;
  }
  div.DraftEditor-editorContainer {
    min-height: inherit;
  }
  div.public-DraftEditor-content {
    min-height: inherit;
  }

  div.DraftEditor-editorContainer > div {
    padding: ${p => p.theme.spacing(1)};
  }

  .selected,
  &:focus-within {
    div.rdw-editor-wrapper {
      outline: 1px auto Highlight;
      outline: 1px auto -webkit-focus-ring-color;
    }
  }

  div.rdw-link-modal.EditorLink {
    position: absolute;
    left: -145px;
    width: 300px;
    height: 116px;
    .rdw-link-modal-label:first-child {
      display: none;
    }
    .rdw-link-modal-input#linkTitle {
      display: none;
    }
    .rdw-link-modal-target-option {
      display: none;
    }
  }

  div.rdw-editor-toolbar {
    background-color: lightgrey;
    border-radius: 4px 4px 0px 0px;
    border: 1px solid ${p => p.theme.palette.grey[400]};
    border-bottom: unset;
    margin-bottom: unset;
  }

  &&& h6.MuiTypography-subtitle1 {
    margin-bottom: unset;
  }

  div.rdw-option-wrapper {
    background: none;
    border: none;
    padding: 0;
    height: ${p => p.theme.spacing(4)};
    width: ${p => p.theme.spacing(4)};

    &.rdw-option-active {
      background: rgba(0, 0, 0, 0.2);
      box-shadow: none;
    }

    &:hover,
    &:focus {
      background: rgba(0, 0, 0, 0.1);
    }
  }

  .public-DraftStyleDefault-block,
  .public-DraftStyleDefault-ul,
  .public-DraftStyleDefault-ol {
    margin: 0;
  }
`;

export default EditorField;
