import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import FileTree from './common/FileTree';

import ProgressBar from '~sections/ProgressBar';
import PageContent from '~sections/PageContent';
import Box from '~layout/Box';
import { BaseFile, File, FolderTreeEntry } from '~common/content.types';
import {
  useGetFolderQueries,
  useGetFolderTreeQuery,
} from '~common/content.api';
import {
  useCurrentFolderId,
  updateContentCriteria,
} from '~common/content.utils';

interface CommonProps<T extends BaseFile>
  extends Omit<
    React.ComponentProps<typeof FileTree>,
    'treeContent' | 'onSelectItem' | 'selectedItems' | 'multiSelect'
  > {
  setOpen?: (open: boolean) => void;
  selection?: string[];
  maxHeight?: string;
  px?: number;
  allowDrag?: boolean;
  type?: 'folder' | 'archive';
  disabledNode?: (node: T, parents: T[]) => boolean;
}

interface MultiSelectProps extends CommonProps<BaseFile> {
  onSelect: (ids: string[]) => void;
  multiSelect: true;
}

interface SingleSelectProps extends CommonProps<BaseFile> {
  onSelect?: (id: string) => void;
  multiSelect?: boolean;
}

interface Props extends CommonProps<BaseFile> {
  onSelect?: ((id: string) => void) | ((ids: string[]) => void);
  multiSelect?: boolean;
}

function FolderTree(props: SingleSelectProps): JSX.Element;
function FolderTree(props: MultiSelectProps): JSX.Element;
function FolderTree({
  setOpen,
  selection = [],
  onSelect,
  maxHeight,
  px,
  allowDrag = true,
  multiSelect,
  type = 'folder',
  disabledNode,
  ...fileTreeProps
}: Props) {
  const language = useSelector(state => state.app.settings?.language);
  const currentFolderId = useCurrentFolderId();

  // We want to memoize the args as RTK query checks sameness by reference
  // for deeply nested data
  const queryArgs = useMemo(
    () =>
      selection.length > 0
        ? selection.map(id => ({ id }))
        : currentFolderId
        ? [{ id: currentFolderId }]
        : [],
    [selection.join(','), currentFolderId]
  );
  const { data: folders } = useGetFolderQueries(queryArgs);
  const { data: folderTree, isFetching } = useGetFolderTreeQuery({
    language,
    type,
    depth: 2,
  });

  if (isFetching) return <ProgressBar />;

  return (
    <Wrapper>
      <StyledPage>
        <StyledContent
          maxHeight={maxHeight}
          padding={2}
          paddingHoriz={px !== undefined ? px : 2}
        >
          <Box>
            <FileTree
              selectedItems={
                folders
                  ? folders
                      .filter((folder): folder is File => !folder.removed)
                      .map(folder => ({ ...folder, ...folder.node }))
                  : undefined
              }
              onSelectItem={(id: string | string[]) => {
                if (onSelect) onSelect(id as string & string[]);
                else
                  updateContentCriteria(
                    id as string,
                    {
                      selectedIndex: -1,
                      selectedId: null,
                    },
                    null,
                    null,
                    type === 'archive' ? '/archive' : '/folders' // REFACTOR: Should be first param and mandatory
                  );
              }}
              treeContent={folderTree as FolderTreeEntry}
              {...fileTreeProps}
              allowDrag={allowDrag}
              multiSelect={multiSelect}
              type={type}
              disabledNode={disabledNode}
            />
          </Box>
        </StyledContent>
      </StyledPage>
    </Wrapper>
  );
}

const StyledContent = styled(PageContent)`
  padding-top: 14px; /* Align with the select all checkbox */
`;

const StyledPage = styled.div`
  overflow: auto;
  display: flex;
`;

const Wrapper = styled.div`
  flex-direction: column;
  width: 100%;
  height: 100%;
  display: flex;
  overflow: auto;
`;

export default FolderTree;
